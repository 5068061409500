import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Select,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useEffect, useMemo, useState } from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"
import { buildParams } from "utils/helpers"

import LocationFilter from "./LocationFilter"

export type Filter = {
  displayName?: string
  id: string
} & (
  | { type: "text" }
  | {
      type: "single" | "multi" | "multi-select"
      options: { label: string; value: string }[]
    }
  | { type: "text" | "location" }
)

export const defaultFilters: Filter[] = [
  {
    displayName: "Subject",
    id: "subject_id",
    type: "multi-select",
    options: [],
  },
  {
    displayName: "Curriculum",
    id: "curriculum_board",
    type: "multi",
    options: [],
  },
  {
    displayName: "Location",
    id: "location",
    type: "location",
  },
]

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "4px",
    height: "max-content",
  },
  userName: {
    "&:hover": {
      textDecorationColor: "black",
    },
  },
  imageContainer: {
    position: "relative",
    height: "max-content",
  },
  image: {
    width: "48px",
    height: "48px",
  },
  imageBadge: {
    position: "absolute",
    bottom: "0",
    right: "-3px",
  },
  separator: {
    color: theme.colors.primary[300],
  },
  optionsContainer: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    maxHeight: 300,
    overflowY: "auto",
    minHeight: "60px",
  },
}))

type Props = {
  className?: string
  filters: Filter[]
  otherFilters?: Record<string, string>
  onChange?: (params: URLSearchParams) => void
}

type SelectedFilters = {
  [key: string]: string | string[]
}

const getInitialState = (filters: Filter[], searchParams: URLSearchParams) => {
  const initialState: SelectedFilters = {}

  filters.forEach(filter => {
    switch (filter.type) {
      case "multi":
      case "multi-select":
      case "single": {
        const key = searchParams.get(filter.id)
        initialState[filter.id] = key ? key.split(",") : []
        break
      }

      case "text": {
        initialState[filter.id] = searchParams.get(filter.id) || ""
        break
      }
      case "location": {
        initialState.country_id = searchParams.get("country_id") || ""
        initialState.state_id = searchParams.get("state_id") || ""
        break
      }
      default:
        break
    }
  })

  return initialState
}

const Filters = React.memo(
  ({ className, filters, onChange, otherFilters = {} }: Props) => {
    const classes = useStyles()

    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
      getInitialState(filters, searchParams)
    )

    useEffect(() => {
      const params = buildParams({
        ...selectedFilters,
        ...otherFilters,
      })
      // console.log(selectedFilters, params.toString())
      if (onChange) onChange(params)

      // Update query params in the browser URL
      setSearchParams(params, { replace: true })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters, setSearchParams, otherFilters])

    const toggleValue = (array: string[], value: string) => {
      if (array.includes(value)) {
        return array.filter((v: any) => v !== value)
      }
      return [...array, value]
    }

    const handleFilterChange = (
      filter: Filter,
      value: string | { country: string; state: string | null } | string[]
    ) => {
      if (typeof value !== "string" && "country" in value) {
        return setSelectedFilters(prevState => ({
          ...prevState,
          country_id: value.country,
          state_id: value.state || "",
        }))
      }

      if (typeof value === "string") {
        switch (filter.type) {
          case "multi":
          case "single": {
            setSelectedFilters(prevState => {
              const newFilters = {
                ...prevState,
                // @ts-expect-error This will be fixed when we streamline the type of SelectedFilters
                [filter.id]: toggleValue(prevState[filter.id], value),
              }
              return newFilters
            })
            break
          }

          case "text": {
            setSelectedFilters(prevState => ({
              ...prevState,
              [filter.id]: value,
            }))
            break
          }

          default:
            break
        }
      } else if (Array.isArray(value)) {
        setSelectedFilters(prevState => ({ ...prevState, [filter.id]: value }))
      }
    }

    const handleClearFilters = () => {
      const clearedFilters: SelectedFilters = {}
      Object.keys(selectedFilters).forEach(filter => {
        clearedFilters[filter] = ""
      })

      setSelectedFilters(clearedFilters)
    }

    const enableFiltersButton = useMemo(
      () =>
        Object.values(selectedFilters).some(item => {
          if (Array.isArray(item)) return item.length > 0

          if (item !== "") return true

          return false
        }),
      [selectedFilters]
    )

    return (
      <div className={className}>
        <div className="mb-1.5 flex justify-end">
          <Button
            disabled={!enableFiltersButton}
            variant="text"
            onClick={handleClearFilters}
          >
            Clear All Filters
          </Button>
        </div>
        <div className={clsx("flex flex-col gap-3 p-2", classes.root)}>
          {filters.map((filter, index) => (
            <div key={index}>
              {filter.displayName && (
                <>
                  <div className="flex items-center justify-between">
                    <Typography className="mb-1" variant="strong">
                      {filter.displayName}
                    </Typography>
                    {(filter.type === "multi" || filter.type === "single") && (
                      <Button
                        variant="text"
                        onClick={() => {
                          setSelectedFilters(prevState => ({
                            ...prevState,
                            [filter.id]: [],
                          }))
                        }}
                      >
                        Clear
                      </Button>
                    )}
                  </div>
                  <Divider className="mb-2.5" />
                </>
              )}

              <div className={classes.optionsContainer}>
                {"options" in filter && filter.options.length === 0 ? (
                  <div className="flex justify-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {filter.type === "multi" &&
                      filter.options.map(option => (
                        <div key={option.value}>
                          <Checkbox
                            checked={selectedFilters[filter.id].includes(
                              option.value
                            )}
                            className="mb-1.5"
                            defaultChecked={undefined}
                            label={option.label}
                            value={option.value}
                            onChange={() => {
                              handleFilterChange(filter, option.value)
                            }}
                          />
                        </div>
                      ))}

                    {/* TODO: render radio */}
                    {filter.type === "single" &&
                      filter.options.map(option => (
                        <div key={option.value}>
                          <Checkbox
                            checked={selectedFilters[filter.id].includes(
                              option.value
                            )}
                            className="mb-1.5"
                            defaultChecked={undefined}
                            label={option.label}
                            value={option.value}
                            onChange={() => {
                              handleFilterChange(filter, option.value)
                            }}
                          />
                        </div>
                      ))}

                    {filter.type === "text" && (
                      <TextField
                        placeholder={filter.displayName}
                        value={selectedFilters[filter.id]}
                        fullWidth
                        // onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                        //   handleFilterChange(
                        //     { name: filter.name, type: filter.type },
                        //     e.target.value,
                        //     e
                        //   )
                        // }
                        // TODO: Add debounce here
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleFilterChange(filter, e.target.value)
                        }
                      />
                    )}

                    {filter.type === "location" && (
                      <LocationFilter
                        value={{
                          country: selectedFilters.country_id as string,
                          state: selectedFilters.state_id as string,
                        }}
                        onChange={val => {
                          handleFilterChange(
                            { id: "location", type: "location" },
                            val
                          )
                        }}
                      />
                    )}

                    {filter.type === "multi-select" && (
                      <Select
                        className="p-0.5"
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        options={filter.options}
                        placeholder={
                          filter.options.length > 0 &&
                          `Ex: ${filter.options[0].label}`
                        }
                        value={filter.options.filter(option =>
                          selectedFilters[filter.id].includes(option.value)
                        )}
                        fullWidth
                        isClearable
                        isMulti
                        isSearchable
                        mountOnBody
                        required={false}
                        onChange={v => {
                          handleFilterChange(
                            filter,
                            v.map(va => va.value)
                          )
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
)

Filters.displayName = "Filters"

export default Filters
