import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)
const getGravityURL = getServiceURL(ServicePrefix.gravity)

export const urls = validateUrls({
  academics: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/academics/${username ? `${username}/` : ""}`
      ),
  },
  interests: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/interests/${username ? `${username}/` : ""}`
      ),
  },
  qualification: {
    create: () => getSolisURL(`/v1/profile/qualifications/`),
    detail: id => getSolisURL(`/v1/profile/qualifications/${id}/`),
    evidence: id => getSolisURL(`/v1/profile/qualifications/${id}/evidences`),
  },
  qualificationLevels: {
    list: () => getGravityURL("/qualification-levels/"),
  },

  profile: {
    public: () => getSolisURL(`/v1/profile/public/`),
    detail: () => getSolisURL(`/v1/profile/`),
    markAsComplete: () => "/jobs/users/marketing-form/complete/",
  },

  workExperiences: {
    detail: id => getSolisURL(`/v1/profile/work-experiences/${id}/`),
    list: username =>
      getSolisURL(
        `/v1/profile/public/work-experiences/${username ? `${username}/` : ""}`
      ),
    create: () => getSolisURL("/v1/profile/work-experiences/"),
  },
})
