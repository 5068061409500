import axios from "api/axios"
import { APIResponse, Config } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Enrollment, ValidateResponse } from "./types"
import { urls } from "./urls"

export default {
  validate: async ({
    data,
  }: Config): Promise<APIResponse<ValidateResponse>> => {
    try {
      const res = await axios.post(urls.validate(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  list: async (): Promise<APIResponse<Enrollment[]>> => {
    try {
      const res = await axios.get(urls.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
