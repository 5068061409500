import {
  CircularProgress,
  Container,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Amenity,
  Gallery as GalleryType,
  Perk,
  SchoolProfile as ProfileType,
} from "api/resources/schools/types"
import Cover from "components/schoolProfile/Cover"
import Gallery from "components/schoolProfile/Gallery"
import JobOpenings from "components/schoolProfile/JobOpenings"
import Overview from "components/schoolProfile/Overview"
import PerksAndAmenities from "components/schoolProfile/PerksAndAmenities"
import ReactHelmet from "components/shared/ReactHelmet"
import React, { useEffect, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import useArray from "utils/hooks/useArray"

import SchoolProfileContext from "../../views/schoolProfile/context"

const useStyles = createUseStyles(theme => ({
  container: () => ({
    position: "relative",
    paddingBottom: theme.spacing(2),
  }),
}))

type Props = {
  slug: string
}

const SchoolProfile = ({ slug }: Props) => {
  const [profile, setProfile] = useState<ProfileType>({
    id: 0,
    name: "",
    slug: "",
    website: "",
    branch: null,
    email: "",
    phoneNumber: null,
    address: "",
    description: "",
    logo: "",
    city: null,
    pincode: null,
    coverImage: "",
    dateEstablished: "",
    workingDays: [],
    dayStartTime: "",
    dayEndTime: "",
    teachingMode: 3,
    isVerified: false,
    managementMessage: null,
    curriculumBoard: [],
    galleryCount: 0,
    gallery: [],
    perks: [],
    amenities: [],
    state: null,
    country: null,
  })
  const theme = useTheme<Theme>()
  const classes = useStyles()
  const isXs = useMediaQuery(theme.breakpoints.only("xs"))

  const [loading, setLoading] = useState(true)
  const gallery = useArray<GalleryType>([])
  const amenities = useArray<Amenity>([])
  const perks = useArray<Perk>([])

  // const trackProfileView = (data: {
  //   schoolName: string
  //   branchName: string | null
  // }) => {
  //   return
  // }

  useEffect(() => {
    async function getProfile() {
      setLoading(true)

      const res = await api.schools.retrieve({
        params: {
          fields: [
            "gallery",
            "curriculum_board",
            "amenities",
            "perks",
            "management_message",
            "gallery_count",
          ],
          photos: 20,
        },
        urlParams: { slug },
      })

      if (res.isSuccessful) {
        setProfile(res.data)
        // trackProfileView({
        //   schoolName: res.data.name,
        //   branchName: res.data.branch,
        // })
        if (res.data.gallery) {
          gallery.set(res.data.gallery)
        }
        if (res.data.amenities) {
          amenities.set(res.data.amenities)
        }
        if (res.data.perks) {
          perks.set(res.data.perks)
        }
        setLoading(false)
      }
    }
    getProfile()
    // Will run only on first mount of component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SchoolProfileContext.Provider
      value={{
        profile,
        gallery: {
          data: gallery.array,
          set: gallery.set,
        },
        perks: {
          data: perks.array,
          set: perks.set,
        },
        amenities: {
          data: amenities.array,
          set: amenities.set,
        },
      }}
    >
      <ReactHelmet
        data={{
          title: profile.name ? `${profile.name}` : "School Profile",
          description: "School Profile",
          url: window.location.href,
          coverImage: "https://jobs.suraasa.com/assets/suraasa-meta-img.jpg",
        }}
      />
      <div>
        {loading ? (
          <div className="mt-20 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Cover isXs={isXs} />
            {isXs ? (
              <div className={classes.container}>
                <Overview />
                <Gallery />
                <JobOpenings slug={slug} />
                <PerksAndAmenities />
              </div>
            ) : (
              <Container className={classes.container}>
                <Overview />
                <Gallery />
                <JobOpenings slug={slug} />
                <PerksAndAmenities />
              </Container>
            )}
          </>
        )}
      </div>
    </SchoolProfileContext.Provider>
  )
}

export default SchoolProfile
