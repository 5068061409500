import React, { useState } from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { SCHOOL_PLACEHOLDER_LOGO } from "utils/constants"

const useStyles = createUseStyles(theme => ({
  imageBorder: {
    border: `1px solid ${theme.colors.surface[300]}`,
    borderRadius: theme.spacing(0.5),
  },
}))

function SchoolLogo({
  src,
  alt,
  className,
}: {
  src: string | null | undefined
  alt?: string
  className?: string
}) {
  const [isError, setIsError] = useState(false)
  const classes = useStyles()

  return (
    <img
      alt={alt || ""}
      className={clsx(
        { [classes.imageBorder]: isError },
        "object-cover rounded-[4px]",
        className
      )}
      src={src || ""}
      onError={e => {
        e.currentTarget.src = SCHOOL_PLACEHOLDER_LOGO
        return setIsError(true)
      }}
    />
  )
}

export default SchoolLogo
