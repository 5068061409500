import React from "react"

const HomeLine = (props: React.SVGProps<any>) => (
  <svg
    color="currentColor"
    fill="none"
    height="1.5em"
    viewBox="0 0 24 24"
    width="1.5em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.09052 14.8285H13.7572M9.60528 2.96508L3.95333 7.36104C3.57552 7.65489 3.38661 7.80182 3.25052 7.98582C3.12997 8.14881 3.04016 8.33243 2.98552 8.52765C2.92383 8.74804 2.92383 8.98736 2.92383 9.46599V15.4951C2.92383 16.4286 2.92383 16.8953 3.10548 17.2518C3.26527 17.5654 3.52024 17.8204 3.83385 17.9802C4.19037 18.1618 4.65708 18.1618 5.59051 18.1618H15.2572C16.1907 18.1618 16.6574 18.1618 17.0139 17.9802C17.3275 17.8204 17.5825 17.5654 17.7423 17.2518C17.9239 16.8953 17.9239 16.4286 17.9239 15.4951V9.46599C17.9239 8.98736 17.9239 8.74804 17.8622 8.52765C17.8076 8.33243 17.7178 8.14881 17.5972 7.98582C17.4611 7.80182 17.2722 7.65489 16.8944 7.36104L11.2425 2.96508C10.9497 2.73737 10.8033 2.62351 10.6417 2.57974C10.499 2.54113 10.3487 2.54113 10.2061 2.57974C10.0444 2.62351 9.89805 2.73737 9.60528 2.96508Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.49998"
    />
  </svg>
)

export default HomeLine
