import "react-image-gallery/styles/css/image-gallery.css"

import { Button, Dialog, IconButton, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavArrowLeft, NavArrowRight, Xmark } from "iconoir-react"
import React, { useContext, useState } from "react"
import ImageGallery from "react-image-gallery"
import { createUseStyles } from "react-jss"
import { DEFAULT_GALLERY_PIC, tabsProfile } from "utils/constants"
import SchoolProfileContext from "views/schoolProfile/context"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },

  imgContainer: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(0.5),
    width: "160px",
    height: "160px",
    objectFit: "cover",
  },

  floatingViewButton: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,

    "& > button": {
      borderRadius: theme.spacing(0, 0, 1, 1),
    },
  },

  galleryDialog: {
    background: "none",
  },

  leftNavArrow: {
    position: "absolute",
    zIndex: 15,
    left: "-100px",
    top: "42%",
    [theme.breakpoints.down("xs")]: {
      left: 0,
      top: "36%",
    },
  },

  rightNavArrow: {
    position: "absolute",
    zIndex: 15,
    right: "-100px",
    top: "42%",
    [theme.breakpoints.down("xs")]: {
      right: 0,
      top: "36%",
    },
  },

  galleryContainer: {
    width: "80vw",
    maxWidth: "1000px",
    maxHeight: "600px",

    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },

  closeButton: {
    borderRadius: "100%",
    width: "min-content",
    overflow: "hidden",
    position: "relative",
    top: "-2%",
    right: "-102%",
    [theme.breakpoints.down("xs")]: {
      right: "-95%",
    },
  },

  thumbnail: {
    "& > span > img": {
      height: "80px",
      objectFit: "cover",

      [theme.breakpoints.down("xs")]: {
        maxHeight: "60px",
      },
    },
  },

  images: {
    background: "black",
    "& > img": {
      height: "500px",
      objectFit: "contain",
      [theme.breakpoints.down("xs")]: {
        height: "251px",
      },
    },
  },
}))

function Gallery() {
  const classes = useStyles()
  const { gallery, profile } = useContext(SchoolProfileContext)
  const [startGalleryIndex, setStartGalleryIndex] = useState(0)

  const [openImageGallery, setOpenImageGallery] = useState(false)

  const images = gallery.data.map(item => ({
    original: item.photo,
    thumbnail: item.photo,
    thumbnailClass: classes.thumbnail,
    originalClass: classes.images,
  }))

  return (
    <div
      className={clsx(
        "mt-3 px-2 pb-6 pt-3.5 sm:px-5 sm:py-4",
        classes.container
      )}
      id={tabsProfile.gallery}
    >
      <div className="flex items-center justify-between">
        <Typography variant="title3">Gallery</Typography>
      </div>
      {profile.galleryCount > 0 ? (
        <div className="my-3 flex flex-wrap gap-2 sm:gap-4">
          {gallery.data.slice(0, 8).map((item, index) => (
            <button
              key={item.id}
              onClick={() => {
                setOpenImageGallery(true)
                setStartGalleryIndex(index)
              }}
            >
              <img
                alt="school"
                className={classes.imgContainer}
                src={item.photo}
                onError={e => (e.currentTarget.src = DEFAULT_GALLERY_PIC)}
              />
            </button>
          ))}
        </div>
      ) : (
        <Typography className="mt-1" color="onSurface.400" variant="smallBody">
          No photos available
        </Typography>
      )}
      {gallery.data.length > 8 && (
        <>
          <div className="hidden justify-end sm:flex ">
            <Button
              variant="text"
              onClick={() => {
                setStartGalleryIndex(0)
                setOpenImageGallery(true)
              }}
            >
              View All Photos
            </Button>
          </div>

          <div className={clsx(classes.floatingViewButton, "sm:hidden")}>
            <Button
              variant="filled"
              fullWidth
              onClick={() => {
                setStartGalleryIndex(0)
                setOpenImageGallery(true)
              }}
            >
              View All Photos
            </Button>
          </div>
        </>
      )}

      <Dialog
        className={clsx(
          "flex items-center justify-center",
          classes.galleryDialog
        )}
        open={openImageGallery}
        fullScreen
        onRequestClose={() => setOpenImageGallery(false)}
      >
        <div className={clsx(classes.galleryContainer, "px-2")}>
          <div className={classes.closeButton}>
            <IconButton
              color="white"
              size="xs"
              variant="filled"
              onClick={() => {
                setOpenImageGallery(false)
              }}
            >
              <Xmark height="25px" width="25px" />
            </IconButton>
          </div>
          <ImageGallery
            items={images}
            renderLeftNav={onClick => (
              <button className={classes.leftNavArrow} onClick={onClick}>
                <NavArrowLeft color="white" height="60px" width="60px" />
              </button>
            )}
            renderRightNav={onClick => (
              <button className={classes.rightNavArrow} onClick={onClick}>
                <NavArrowRight color="white" height="60px" width="60px" />
              </button>
            )}
            showFullscreenButton={false}
            showPlayButton={false}
            slideDuration={200}
            startIndex={startGalleryIndex}
            lazyLoad
            onErrorImageURL={DEFAULT_GALLERY_PIC}
          />
        </div>
      </Dialog>
    </div>
  )
}
export default Gallery
