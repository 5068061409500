import api from "api"
import { AcceptedJobOverview } from "api/resources/jobs/types"
import ErrorPage from "components/ErrorPage"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { GlobalContext } from "GlobalState"
import { useContext, useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { clearAuthInfo } from "utils/auth"
import { routes } from "utils/routes"

const CheckAccess = () => {
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [skipJobLockedTill, setSkipJobLockedTill] = useState(false)

  const navigate = useNavigate()

  const { overview } = useContext(GlobalContext)

  useEffect(() => {
    const getOverview = async () => {
      setLoading(true)
      const res = await api.jobs.overview()
      if (res.isSuccessful) {
        // @ts-expect-error type narrowing needed
        if (res.data.jobsLockedTill !== undefined) {
          overview.set(res.data as AcceptedJobOverview)
          navigate(routes.acceptedOffer)
          setSkipJobLockedTill(false)
        } else {
          setSkipJobLockedTill(true)
        }
      } else {
        if (res.statusCode === 401) {
          clearAuthInfo()
          window.location.reload()
        }
      }
      setLoading(false)
    }

    getOverview()
    /**
     * if we put overview in deps,
     * it would set-off an infinite loop because
     * we are setting overview in getOverview()
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {loading ? (
        <LoadingOverlay showLogo />
      ) : (
        error && <ErrorPage message="You don't have access to this page" />
      )}
      {!loading && !error && <Outlet />}
    </>
  )
}

export default CheckAccess
