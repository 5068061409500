import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Job } from "api/resources/jobs/types"
import clsx from "clsx"
import { ArrowDown } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { tabsProfile } from "utils/constants"
import toast from "utils/toast"

import JobCard from "./JobCard"

const useStyles = createUseStyles(theme => ({
  container: {
    background: theme.colors.common.white[500],
    borderRadius: theme.spacing(1.5),
    border: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobOpenings({ slug }: { slug: string }) {
  const classes = useStyles()
  const [showAll, setShowAll] = useState(false)
  const [jobList, setJobList] = useState<Job[]>([])
  const [loading, setLoading] = useState(false)
  const [totalJobs, setTotalJobs] = useState<number | null>(0)

  async function getJobs() {
    const res = await api.jobs.list({
      params: {
        fields: ["date_published"],
        size: 6,
        page: showAll ? "all" : "1",
        school: slug,
      },
    })

    if (res.isSuccessful) {
      setTotalJobs(res.data.total)
      setJobList(res.data.data)
    } else {
      toast.error(res.errors.message || "Something Went Wrong")
    }
    setLoading(false)
  }

  useEffect(() => {
    if (showAll) {
      setLoading(true)
    }

    getJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAll, slug])

  return (
    <>
      <div
        className={clsx("mt-3 px-2 py-3.5 sm:px-5 sm:py-4", classes.container)}
        id={tabsProfile.jobs}
      >
        <div className="mb-4 flex items-center justify-between">
          <Typography variant="title3">Job Openings</Typography>
        </div>

        {jobList.length ? (
          <>
            <div className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3">
              {jobList.map(item => (
                <JobCard {...item} key={item.id} onApply={() => getJobs()} />
              ))}
            </div>

            {((totalJobs && totalJobs > 6) || loading) && (
              <>
                <Divider className="mb-0.5 mt-3" color="onSurface.200" />
                <div className="flex justify-end">
                  <Button
                    color="black"
                    endAdornment={<ArrowDown />}
                    loading={loading}
                    variant="text"
                    onClick={() => setShowAll(true)}
                  >
                    View All Job Openings
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <Typography
            className="mb-0.5"
            color="onSurface.400"
            variant="smallBody"
          >
            Looks like there&apos;s nothing here
          </Typography>
        )}
      </div>
    </>
  )
}

export default JobOpenings
