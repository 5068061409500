import { Country, Currency, Curriculum, State, Subject } from "../global/types"

export enum JobInterestedUserStatus {
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  WITHDRAWN = "withdrawn",
}

export enum JobInvitedUserStatus {
  NOT_SENT = "not_sent",
  PENDING = "pending",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  REVOKED = "revoked",
  CANCELLED = "cancelled",
}
export enum JobApplicantStatus {
  PENDING = "pending",
  IN_PROGRESS = "in_progress",
  REJECTED = "rejected",
  JOB_OFFER_SENT = "job_offer_sent",
  JOB_OFFER_ACCEPTED = "job_offer_accepted",
  JOB_OFFER_REJECTED = "job_offer_rejected",
  WITHDRAWN = "withdrawn",
  CANCELLED = "cancelled",
}

export type Job = {
  school: Pick<
    School,
    | "country"
    | "curriculumBoard"
    | "logo"
    | "name"
    | "state"
    | "email"
    | "slug"
    | "branch"
    | "city"
  >
  id: number
  slug: string
  position: string
  subject: Subject
  currency: Currency | null
  salaryOffered: number | null
  salaryNegotiable: boolean
  maximumSalaryOffered: number | null
  datePublished: string | null
  numberOfApplicants: string | null
  description: string | null
  eligibilityCriterion: "qualification" | "all"

  // If we're viewing the job for a particular user, we may request these fields from the backend
  jobApplicant: {
    id: number
    status: JobApplicantStatus
  } | null
  jobInterestedUser: {
    id: number
    status: JobInterestedUserStatus
  } | null
  jobInvitedUser: {
    id: number
    status: JobInvitedUserStatus
  } | null
}

export type ActiveJobOverview = {
  newInvites: number
  newOffers: number
  totalActiveApplications: number
  totalApplied: number
  totalInvites: number
  totalJobOffers: number
}

export type Overview = ActiveJobOverview | AcceptedJobOverview

export type JobOverview = Pick<Job, "id" | "slug" | "position" | "subject">

export type JobApplicant = {
  id: number
  status: JobApplicantStatus
  attemptNumber: number
  job: number
  user: string
}

export enum ApplicationStatus {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum JobOfferStatus {
  SENT = "sent",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
}

export type JobOverviewType = {
  uuid: string
  eligibleForJobs: boolean
  jobsLockedTill: string | null
}

export type AcceptedJobOverview = {
  jobsLockedTill?: string
  jobOffer?: JobOfferDetailsType
}

export type JobOfferDetailsType = {
  description: string
  finalSalary: number
  currency: Currency
  dateCreated: string
  joiningDate: string
  offerLetter: string
  offerLetterName: string
  status: JobOfferStatus
  job: Job
  user: string
}

export type SchoolOverview = Pick<
  School,
  "id" | "logo" | "name" | "country" | "state" | "slug"
>

export type JobOfferType = {
  id: number
  status: JobOfferStatus
  school: SchoolOverview
  job: JobOverview
  position: string
  dateCreated: string
  user: number
  isViewed: boolean
  subject: Subject
  currency: Currency
  salaryOffered: number
  maximumSalaryOffered: number | null
}

export type School = {
  id: number
  branch: string | null
  city: string | null
  name: string
  country: Country | null
  state: State | null
  slug: string
  website: string
  workingDays: number
  workHoursPerDay: number
  phoneNumber: string
  email: string
  teachingMode: number
  admin: string
  logo: string | null
  coverImage: string
  description: string
  dateEstablished: string
  isVerified: boolean
  curriculumBoard: Curriculum[]
}

/**
 * Timeline
 */
export type BaseStep = {
  dateCreated: string
  dueDate: string
  id: number
  step: InterviewDetailsType | LiveDemoDetailsType | AssessmentDetailsType
  stepId: number
  // stepNumber: number
  stepType: StepType
  status: JobApplicantStepStatus
}

export interface AssessmentDetailsStep extends BaseStep {
  stepType: StepType.ASSESSMENT
  step: AssessmentDetailsType
}
export interface InterviewDetailsStep extends BaseStep {
  stepType: StepType.INTERVIEW
  step: InterviewDetailsType
}
export interface LiveDemoDetailsStep extends BaseStep {
  stepType: StepType.LIVE_DEMO
  step: LiveDemoDetailsType
}

export type Step =
  | AssessmentDetailsStep
  | LiveDemoDetailsStep
  | InterviewDetailsStep

export enum StepType {
  LIVE_DEMO = "livedemo",
  INTERVIEW = "interview",
  ASSESSMENT = "assessment",
}

export type AssessmentDetailsType = {
  id: number
  title: string
  duration: number
  dueDate: string | null
  dateCreated: string
  scheduledTime: string
  totalNumberOfQuestions: number
}

export type InterviewDetailsType = {
  scheduledTime: string
  id: number
  duration: number
  name: string
  isTelephonic: boolean
  videoMeeting: any | null
  url: string | null
}

export type LiveDemoDetailsType = {
  id: number
  name: string
  scheduledTime: string
  instructions: string
  isVirtual: boolean
  duration: number
  topic: string | null
  address: string | null
  meetingUrl: string | null
}

export enum JobApplicantStepStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  SKIPPED = "skipped",
}

export type CurrentStep = Step & {
  score: number | null
  startTime: string | null // ISODate
  endTime: string | null // ISODate
}

export type ActiveApplication = {
  id: number
  job: {
    id: number
    position: string
    subject: {
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
  }
  jobOffer?: JobOfferType
  status: JobApplicantStatus
  school: {
    slug: string
    country: {
      uuid: string
      name: string
      isoCode: string
      isoCode3: string
    } | null
    state: {
      uuid: string
      name: string
      isoCode: string
      countryId: string
    } | null
    name: string
    logo: string
  }
  upcomingSteps: BaseStep[]
  upcomingStepCount: number
}

export type Application = {
  id: number
  invitationExpiresAt: string
  school: {
    slug: string
    country: {
      uuid: string
      name: string
      isoCode: string
      isoCode3: string | null
    } | null
    state: {
      uuid: string
      name: string
      isoCode: string
      countryId: string
    } | null
    name: string
    logo: string | null // URL
  }
  job: {
    id: number
    subject: {
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
    position: string
  }
  isViewed: boolean
  applicants: number
  dateApplied: string
}

export type StepsOverview = {
  profileDataExists: boolean
  resumeExists: boolean
  marketingSignupComplete: boolean
  marketingFormDataExists: boolean
  qualificationDataExists: boolean
}

export type Reason = {
  reason: string | undefined
  isOther: boolean
}

export type WithdrawApplicationResponse = {
  id: number
  status: JobApplicantStatus.WITHDRAWN
  attemptNumber: number
  rejectionReason: Reason[]
  isViewed: boolean
  job: number
  user: number
  currentStep: number | null
}

export enum InviteStatus {
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  REVOKED = "revoked",
}

export type InviteUpdateResponse = {
  id: number
  status: "accepted" | "rejected" | "revoked"
  actionBy: "school" | "recruiter"
  sequence: number
  invitationSentOn: string
  user: string
  job: number
}
export enum ActionBy {
  SCHOOL = "school",
  RECRUITER = "recruiter",
}

export type PendingApplication = {
  id: number
  school: {
    country: Country | null
    state: State | null
    name: string
    slug: string
    logo: string | null
  }
  job: {
    id: number
    subject: Subject
    position: string
  }
  jobApplicant?: {
    id: number
  } | null
  date: string
  source: "job_invited_user" | "job_interested_user"
  invitationExpiresAt?: string
  jobInvitedUser?: {
    id: number
  } | null
  actionBy: ActionBy | null
}
