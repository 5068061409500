import ShareSheet from "components/shared/ShareSheet"
import React, { useEffect, useState } from "react"
import { getShareJobContent } from "utils/helpers"

type Props = {
  jobSlug: string
  position: string
  subject: string
  activator: (handleOpen: () => void) => void
  schoolProfile: Parameters<typeof getShareJobContent>[1]["profile"]
}

const ShareJobButton = ({
  activator,
  jobSlug,
  position,
  subject,
  schoolProfile,
}: Props) => {
  const [open, setOpen] = useState(false)

  const shareURL = `${window.location.origin}/school/${schoolProfile.slug}?jobSlug=${jobSlug}`

  const contentData = {
    profile: schoolProfile,
    job: {
      slug: jobSlug,
      position,
      subject,
    },
  }
  useEffect(() => {
    activator(() => setOpen(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <ShareSheet
        data={{
          url: shareURL,
          text: "",
          platformSpecificText: {
            linkedin: getShareJobContent("linkedin", contentData),
            whatsapp: getShareJobContent("whatsapp", contentData),
            facebook: getShareJobContent("facebook", contentData),
            telegram: getShareJobContent("telegram", contentData),
          },
        }}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </div>
  )
}

export default ShareJobButton
