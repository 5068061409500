import { ModeOfLearning } from "utils/constants"
import { ValueOf } from "utils/helpers"

import {
  Country,
  Currency,
  Curriculum,
  QualificationField,
  State,
  TeachingLevel,
  TeachingRole,
} from "../global/types"

export type QualificationLevel = {
  uuid: string
  name: string
}
export type Qualification = {
  id: number
  organisationName: string
  name: string
  qualificationLevelId: string
  qualificationFieldId: string
  qualificationField: QualificationField
  qualificationLevel: QualificationLevel
  startDate: string // Date
  endDate: string // Date
  grade: string
  modeOfLearning: ModeOfLearning
  isVerified: boolean
  evidences: Evidence[]
}

export enum EvidenceType {
  URL = 1,
  File,
}

type BaseEvidence = {
  id: string
}

export type Evidence =
  | (BaseEvidence & {
      evidenceType: EvidenceType.URL
      url: string
    })
  | (BaseEvidence & {
      evidenceType: EvidenceType.File
      file: string
      fileName: string
    })

export enum Gender {
  MALE = "male",
  FEMALE = "female",
  PREFER_NOT_TO_SAY = "prefer_not_to_say",
}
export type Profile = {
  id: string
  resume: string | null
  careerAspiration: string | null
  countriesWorkedIn: { name: string }[]
  // currentWorkingExperiences: WorkExperienceType[]
  countryId: string | null
  country: { name: string; code: string; uuid: string } | null
  dateOfBirth: string | null
  gender: Gender | null
  qualificationField: QualificationField
  isVerified: boolean
  lookingForJobs: boolean
  nationality: { name: string; code: string; uuid: string } | null
  state: { name: string; uuid: string } | null
  stateId: string | null
  phoneNumber: { code: string; number: number } | null
  picture: string | null
  user: {
    email: string
    firstName: string
    lastName: string
    middleName: string
    username: string
    uuid: string
  }
}

export enum EmploymentType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  FRESHER = "fresher",
  INTERN = "intern",
  FREELANCE = "freelance",
  SELF_EMPLOYED = "self_employed",
}

/**
 * Using object literal instead of enum to use null value.
 * Organisation type can either be null or an integer in the range of 1-3
 */
export const OrganisationType = {
  SCHOOL: "school",
  COLLEGE_OR_UNIVERSITY: "college_or_university",
  TUTORING: "tutoring",
  OTHERS: null,
} as const

export type WorkExperienceType = {
  id: string
  title: string
  employmentType: EmploymentType | null
  description: string | null
  organisationName: string
  organisationType: ValueOf<typeof OrganisationType> | null
  otherOrganisationType: string | null
  countryId: string | null
  country: Country | null
  stateId: string | null
  state: State | null
  currentlyWorking: boolean
  startDate: string | null // Date
  endDate: string | null // Date
  salary: number | null
  currencyId: string | null

  curriculum: Curriculum | null
  teachingLevel: TeachingLevel | null
  teachingRole: TeachingRole | null

  curriculumId: string | null
  teachingLevelId: string | null
  teachingRoleId: string | null
  // subjects: { subjectId: string; name: string }[]

  subjects: {
    subjectId: string
    subject: {
      id: number
      uuid: string
      name: string
      addedById: string | null
      isApproved: boolean
    }
  }[]

  currency: Currency | null
}
