import { CircularProgress, Container, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { AcceptedJobOverview } from "api/resources/jobs/types"
import Navbar from "components/shared/Navbar"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { Lock } from "iconoir-react"
import { useContext, useEffect } from "react"
import { createUseStyles } from "react-jss"

import JobPostCard from "./JobPostCard"
import JobOfferDetails from "./OfferLetter/JobOfferDetails"

const useStyles = createUseStyles(theme => ({
  banner: {
    background: theme.colors.surface[100],
  },
}))

const JobLocked = () => {
  const classes = useStyles()

  const {
    overview: { jobsLockedTill, jobOffer, ...overview },
  } = useContext(GlobalContext)

  useEffect(() => {
    const getOverview = async () => {
      const res = await api.jobs.overview()
      if (res.isSuccessful) {
        overview.set(res.data as AcceptedJobOverview)
      }
    }
    if (!jobOffer) {
      getOverview()
    }
    /**
     * if we put overview in deps,
     * it would set-off an infinite loop because
     * we are setting overview in getOverview()
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar gutterBottom={false} hideBackButton />
      <div className={classes.banner}>
        <Container>
          <div className="py-2">
            <div className="mb-1 flex flex-wrap items-end">
              <Lock />
              <Typography color="onSurface.800" variant="largeBody">
                Job platform is locked for your profile
              </Typography>
            </div>
            <Typography color="onSurface.800" variant="smallBody">
              Suraasa Jobs Platform has been locked for you as you recently
              accepted a job offer. You will be able to fully access the
              platform from{" "}
              {jobsLockedTill &&
                format(new Date(jobsLockedTill), "do MMMM yyyy")}
              .
            </Typography>
          </div>
        </Container>
      </div>

      <Container>
        <div className="mb-6 mt-5 flex items-center justify-between">
          <Typography color="onSurface.800" variant="body">
            Accepted Job Offer
          </Typography>
        </div>
        {!jobOffer ? (
          <div className="p-3">
            <CircularProgress />
          </div>
        ) : (
          <>
            <JobPostCard job={jobOffer.job} jobOfferDetails={jobOffer} />
            <JobOfferDetails details={jobOffer} />
          </>
        )}
      </Container>

      <div />
    </>
  )
}

export default JobLocked
