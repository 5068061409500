import React from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import { format } from "date-fns"
import { createUseStyles } from "react-jss"

import { Clock } from "iconoir-react"

import { LiveDemoDetailsStep } from "api/resources/jobs/types"

import ListItem from "../ListItem"

const useStyles = createUseStyles({
  instructions: {
    whiteSpace: "pre-wrap",
    paddingBottom: "8px",
  },
})

type Props = {
  Tag?: React.ReactElement
} & LiveDemoDetailsStep

const LiveDemoItem = ({
  Tag,
  dateCreated,
  step: { name, scheduledTime, meetingUrl, topic, instructions, isVirtual },
}: Props) => {
  const classes = useStyles()
  const getAction = () => {
    if (meetingUrl)
      return (
        <Button
          component="a"
          href={meetingUrl}
          size="sm"
          // @ts-ignore placebo-issue
          target="_blank"
        >
          Join Live Demo
        </Button>
      )

    return null
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col gap ">
            <div className="flex gap-1.5 flex-wrap">
              <Typography color="onSurface.800" variant="strong">
                {name}
              </Typography>
              {Tag}
            </div>
            <Typography
              className="mt-0.5"
              color="onSurface.400"
              variant="smallBody"
            >
              Live Demo {isVirtual ? "(Online)" : "(Offline)"}
            </Typography>
            {topic && (
              <Typography
                className="mt-2"
                color="onSurface.500"
                variant="strongSmallBody"
              >
                Topic: {topic}
              </Typography>
            )}
            {instructions && (
              <Typography
                className={classes.instructions}
                color="onSurface.500"
                variant="smallBody"
              >
                {instructions}
              </Typography>
            )}
          </div>

          <Typography
            className="md:ml-auto"
            color="onSurface.500"
            variant="smallBody"
          >
            Posted on {format(new Date(dateCreated), "do MMM yy")}
          </Typography>
        </div>

        {scheduledTime && (
          <ListItem icon={<Clock />}>
            <Typography
              color="onSurface.400"
              display="inline"
              variant="smallBody"
            >
              {new Date(scheduledTime) < new Date()
                ? "Was scheduled"
                : "Scheduled"}{" "}
              for{" "}
            </Typography>
            <Typography display="inline" variant="strongSmallBody">
              {format(new Date(scheduledTime), "dd MMMM yyyy',' hh:mm aa")}
            </Typography>
          </ListItem>
        )}
        <div className="mt-3 flex justify-end gap-1">{getAction()}</div>
      </div>
    </>
  )
}

export default LiveDemoItem
