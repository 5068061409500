import { Button, Typography } from "@suraasa/placebo-ui"
import ApplyingForJobsIllustration from "assets/illustrations/applying-for-jobs.svg"
import CompleteProfileIllustration from "assets/illustrations/complete-profile.svg"
import GetHiredIllustration from "assets/illustrations/get-hired.svg"
import clsx from "clsx"
import ProfileCompletion from "components/ProfileCompletion"
import { GlobalContext } from "GlobalState"
import { ArrowRight } from "iconoir-react"
import { useContext } from "react"
import { createUseStyles } from "react-jss"
import useToggle from "utils/hooks/useToggle"

const useStyles = createUseStyles(theme => ({
  root: { width: "100%" },

  illustrationWithText: {
    minWidth: "25%",
    height: "100%",
  },

  arrowContainer: {
    width: "35px",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      "& > svg": {
        transform: "rotate(90deg)",
      },
    },
  },

  imageContainer: {
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
    },

    "& > img": {
      width: "inherit",
      objectFit: "cover",
    },
  },

  text: {
    maxWidth: "562px",
  },
}))

const CompleteProfile = () => {
  const classes = useStyles()

  const { stepsOverview } = useContext(GlobalContext)
  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)

  return (
    <>
      {stepsOverview && (
        <ProfileCompletion
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
        />
      )}

      <div
        className={clsx(
          "flex flex-col items-stretch justify-between sm:mb-10 sm:flex-row",
          classes.root
        )}
      >
        <div
          className={clsx(
            "flex flex-col items-center gap-2 sm:gap-6",
            classes.illustrationWithText
          )}
        >
          <div className={classes.imageContainer}>
            <img alt="apply-for-jobs" src={CompleteProfileIllustration} />
          </div>
          <Typography variant="strong">Complete Profile</Typography>
        </div>

        <div
          className={clsx(
            classes.arrowContainer,
            "my-2 flex items-center justify-center sm:my-0"
          )}
        >
          <ArrowRight height={40} width={40} />
        </div>

        <div
          className={clsx(
            "flex flex-col items-center gap-2 sm:gap-6",
            classes.illustrationWithText
          )}
        >
          <div className={classes.imageContainer}>
            <img alt="apply-for-jobs" src={ApplyingForJobsIllustration} />
          </div>

          <Typography variant="strong">Apply for Jobs</Typography>
        </div>

        <div
          className={clsx(
            classes.arrowContainer,
            "my-2 flex items-center justify-center sm:my-0"
          )}
        >
          <ArrowRight height={40} width={40} />
        </div>

        <div
          className={clsx(
            "flex flex-col items-center gap-2 sm:gap-6",
            classes.illustrationWithText
          )}
        >
          <div className={classes.imageContainer}>
            <img alt="get-hired" src={GetHiredIllustration} />
          </div>

          <Typography variant="strong">Get Hired</Typography>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <Button
          className="mb-2.5 mt-4 self-center"
          onClick={() => toggleProfileCompletionDialog()}
        >
          Complete Profile
        </Button>
        <div className="mb-5 grid grid-cols-5 justify-center">
          <Typography
            className={clsx(
              "col-span-5 sm:col-span-3 sm:col-start-2",
              classes.text
            )}
            color="onSurface.500"
            textAlign="center"
            variant="smallBody"
          >
            By completing your profile you allow us to match you with jobs that
            are tailored for your needs. Having a complete profile will
            immensely boost your chances of getting job offers from top schools.
          </Typography>
        </div>
      </div>
    </>
  )
}
export default CompleteProfile
