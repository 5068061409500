import { IconButton, Typography } from "@suraasa/placebo-ui"
import PDFSvg from "assets/PDFSvg.svg"
import clsx from "clsx"
import { Trash } from "iconoir-react"
import { useCallback, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { humaniseBytes, MBToBytes } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  root: {
    "& .box__input": {
      "&.is-dragover": {
        borderColor: theme.colors.primary[400],
        backgroundColor: theme.colors.primary[50],
      },
    },

    "& label": {
      cursor: "pointer",
    },

    "& .box__file": {
      display: "none",
    },

    "& .box__button": {
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(1, 4),
      textTransform: "uppercase",
      letterSpacing: 1.8,
      marginTop: theme.spacing(3),
    },
  },

  instructions: {
    // Does not work without template string
    paddingInlineStart: `${theme.spacing(2)}px`,
    color: theme.colors.onSurface[500],
  },
}))

type FileType = FileList | null | string

type Props = {
  /**
   * Example: [".pdf", ".xlsx", ".docx"]
   */
  allowedExtensions?: string[]
  /**
   * In megabytes
   * Default: 1MB
   */
  maxSize?: number
  instructions?: string[]
  onChange?: (files: FileType) => void

  /** This is required so you can clear the input from outside the component */
  inputId: string

  uploadProgress?: number
}
const DragNDropPDF = ({
  uploadProgress,
  instructions = [],
  allowedExtensions = [],
  maxSize = 1,
  onChange,
  inputId,
}: Props) => {
  const classes = useStyles()

  const [files, setFiles] = useState<FileType>(null)
  const [errors, setErrors] = useState<string[]>([])

  const validateFiles = useCallback(
    (file: FileType) => {
      if (!file || typeof file === "string") return

      if (file.length === 0) return

      setErrors([])

      const fileExt = file[0].name.split(".").pop()
      const fileSize = file[0].size

      if (
        allowedExtensions.length > 0 &&
        !allowedExtensions.includes(`.${fileExt}`)
      ) {
        setErrors(e => [...e, "Invalid file format"])
        return
      }

      if (fileSize > MBToBytes(maxSize)) {
        setErrors(e => [...e, `File size is too large`])
        return
      }

      setFiles(file)
    },
    [allowedExtensions, maxSize]
  )

  useEffect(() => {
    if (typeof onChange === "function") onChange(files)
  }, [files, onChange])

  useEffect(() => {
    const root = document.querySelector("#__fileUploadRoot")
    const box = document.querySelector("#__fileUploadRoot .box__input")

    if (!root) return
    if (!box) return

    const allDragEvents = [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ]

    function preventDefault(e: Event) {
      e.preventDefault()
      e.stopPropagation()
    }
    allDragEvents.forEach(event => {
      root.addEventListener(event, preventDefault, false)
    })

    const dragOverEvents = ["dragover", "dragenter"]
    function handleDragOver() {
      box?.classList.add("is-dragover")
    }
    dragOverEvents.forEach(event => {
      box.addEventListener(event, handleDragOver, false)
    })

    const dragLeaveEvents = ["dragleave", "dragend", "drop"]

    function handleDragLeave() {
      box?.classList.remove("is-dragover")
    }
    dragLeaveEvents.forEach(event => {
      box.addEventListener(event, handleDragLeave, false)
    })

    function handleFileDrop(e: Event) {
      if ("dataTransfer" in e) {
        const droppedFiles = (e as DragEvent).dataTransfer?.files
        validateFiles(droppedFiles ?? null)
      }
    }
    root.addEventListener("drop", handleFileDrop, false)

    return () => {
      allDragEvents.forEach(event => {
        root.removeEventListener(event, preventDefault)
      })
      dragOverEvents.forEach(event => {
        box.removeEventListener(event, handleDragOver)
      })
      dragLeaveEvents.forEach(event => {
        box.removeEventListener(event, handleDragLeave)
      })
      root.removeEventListener("drop", handleFileDrop)
    }
    /**
     * Files is added to the dependency because when files are added,
     * the "box__input" element unmounts, clearing the event listeners.
     * And the listeners don't re-apply if user removes the file and tries to upload again..
     */
  }, [validateFiles, files])

  return (
    <div>
      <div className={clsx(classes.root, "h-full")} id="__fileUploadRoot">
        {files ? (
          <div>
            <div className="flex items-center justify-between rounded-2xl border border-solid border-surface-200 bg-surface-50 p-2">
              <div className="flex items-center space-x-1">
                <img
                  src={PDFSvg}
                  alt="pdf-icon"
                  className="h-7 w-5 shadow-[0px_3px_7.5px_0px_#0000000D]"
                />
                {typeof files !== "string" && (
                  <div>
                    <Typography variant="strong">{files[0].name}</Typography>
                    <Typography variant="smallBody" color="onSurface.500">
                      {humaniseBytes(files[0].size)}{" "}
                      {uploadProgress !== undefined && `| ${uploadProgress}%`}
                    </Typography>
                  </div>
                )}
              </div>
              {uploadProgress === undefined && (
                <IconButton
                  variant="plain"
                  color="black"
                  onClick={() => setFiles(null)}
                >
                  <Trash />
                </IconButton>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div
              // eslint-disable-next-line tailwindcss/no-custom-classname
              className={clsx(
                "box__input flex w-full flex-col items-center justify-center rounded-2xl border-2 border-onSurface-300 p-2 text-center transition-[border-color] duration-[0.15s] ease-linear"
              )}
            >
              <input
                accept={
                  allowedExtensions.length > 0
                    ? allowedExtensions.join()
                    : undefined
                }
                // eslint-disable-next-line tailwindcss/no-custom-classname
                className="box__file"
                id={inputId}
                type="file"
                onChange={e => validateFiles(e.target.files)}
              />
              <label
                className={clsx("flex flex-col items-center gap-1 text-center")}
                htmlFor={inputId}
              >
                <img
                  src={PDFSvg}
                  alt="pdf-icon"
                  className="h-7 w-5 shadow-[0px_3px_7.5px_0px_#0000000D]"
                />

                <Typography variant="title4" className="max-w-[200px]">
                  Drag and drop your file here or{" "}
                  <strong className="text-primary-500 underline">
                    Choose file
                  </strong>
                </Typography>
              </label>
              {errors.map((error, i) => (
                <div className="mt-2 text-critical-500" key={i}>
                  <Typography textAlign="center" variant="smallBody">
                    {error}
                  </Typography>
                </div>
              ))}
            </div>
            <ul className={clsx(classes.instructions, "mt-1 pl-0.5")}>
              {instructions.map((point, i) => (
                <li key={i}>
                  <Typography variant="smallBody">{point}</Typography>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div />
    </div>
  )
}

export default DragNDropPDF
