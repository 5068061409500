import {
  Button,
  Chip,
  IconButton,
  Tag,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Job,
  JobInterestedUserStatus,
  JobInvitedUserStatus,
} from "api/resources/jobs/types"
import clsx from "clsx"
import ShareJobButton from "components/jobs/ShareJobButton"
import AsyncBuilder from "components/shared/AsyncBuilder"
import SchoolLogo from "components/shared/SchoolLogo"
import { formatDistance } from "date-fns"
import { Check, ShareAndroid } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"
import { Link, useNavigate } from "react-router-dom"
import useEnrollments from "utils/hooks/useEnrollments"
import { routes } from "utils/routes"
import toast from "utils/toast"

const useStyles = createUseStyles(theme => ({
  root: {
    cursor: "pointer",
    width: "100%",
  },
  jobTitle: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  card: {
    background: "white",
    borderRadius: "8px",
    border: `1px solid ${theme.colors.surface[200]}`,
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
    "&:focus": {
      outline: "1px solid black",
    },
  },
  logo: {
    filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.05))",
    width: "46px",
    height: "46px",
  },
}))

type Props = {
  data: Job
  onUpdate: (obj: Job) => void
}

const JobCard = ({ data, onUpdate }: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const { isPgCTLUser } = useEnrollments()

  const timeDistance = (time: string) =>
    formatDistance(new Date(time), new Date(), {
      addSuffix: true,
    })

  const registerInterest = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault()

    const res = await api.jobs.interestedUser.create({
      urlParams: {
        jobId: data.id,
      },
    })
    if (res.isSuccessful) {
      onUpdate({
        ...data,
        jobInterestedUser: {
          status: JobInterestedUserStatus.PENDING,
          id: -1,
        },
      })
      toast.success(
        "Great! Thank you for expressing interest in this job. Our placements team will be in touch with you"
      )
    } else {
      toast.error("There was an issue while processing your request")
    }
  }

  const getCTA = () => {
    if (data.jobApplicant) {
      return (
        <Button
          component={Link}
          size="sm"
          to={`${routes.jobTimeline
            .replace(":jobId", String(data.id))
            .replace(":jobApplicant", data.jobApplicant.id.toString())}`}
          variant="text"
        >
          View Application
        </Button>
      )
    }

    if (data.jobInterestedUser) {
      if (data.jobInterestedUser.status === JobInterestedUserStatus.PENDING) {
        return (
          <span>
            <Tooltip
              maxWidth="200px"
              title="Our placements team will be in touch with you"
            >
              <div>
                <Chip
                  color="success"
                  label="Applied"
                  size="sm"
                  startAdornment={<Check strokeWidth={3} />}
                />
              </div>
            </Tooltip>
          </span>
        )
      }
    }

    if (data.jobInvitedUser) {
      if (data.jobInvitedUser.status === JobInvitedUserStatus.PENDING) {
        return (
          <Button
            component={Link}
            size="sm"
            to={`${routes.home}?tab=Pending+Applications&inviteId=${data.jobInvitedUser.id}`}
            variant="outlined"
          >
            View Invitation
          </Button>
        )
      }
    }

    if (data.eligibilityCriterion === "qualification") {
      return (
        <Tooltip
          disabled={isPgCTLUser}
          title="This job is only for Suraasa's PgCTL Learners"
        >
          <div>
            <AsyncBuilder
              handler={registerInterest}
              render={({ onClick, loading }) => (
                <Button
                  disabled={!isPgCTLUser}
                  loading={loading}
                  size="sm"
                  variant="text"
                  component={Link}
                  to={`${routes.schoolProfile.replace(
                    ":slug",
                    data.school.slug
                  )}?jobSlug=${data.slug}&applyingOnJobId=${data.id}&ri=true`}
                >
                  Register Interest
                </Button>
              )}
            />
          </div>
        </Tooltip>
      )
    }

    return (
      <Button
        component={Link}
        size="sm"
        to={`${routes.schoolProfile.replace(
          ":slug",
          data.school.slug
        )}?jobSlug=${data.slug}&applyingOnJobId=${data.id}`}
        variant="text"
      >
        Apply
      </Button>
    )
  }

  const getStatus = () => {
    const datePosted = (
      <Typography color="onSurface.500" variant="smallBody">
        {/* eslint-disable-next-line no-nested-ternary */}
        {data.datePublished
          ? timeDistance(data.datePublished) !== "less than a minute ago"
            ? `Posted ${timeDistance(data.datePublished)}`
            : "Posted recently"
          : ""}
      </Typography>
    )
    return datePosted
  }

  return (
    <div
      className={classes.root}
      role="button"
      tabIndex={0}
      onClick={() => {
        navigate(
          `${routes.schoolProfile.replace(":slug", data.school.slug)}?jobSlug=${
            data.slug
          }`
        )
      }}
      onKeyDown={e => {
        if (e.key === "Enter" || e.key === "Space") {
          navigate(
            `${routes.schoolProfile.replace(
              ":slug",
              data.school.slug
            )}?jobSlug=${data.slug}`
          )
        }
      }}
    >
      <div
        className={clsx(
          "mb-2 flex flex-col justify-between p-3 sm:flex-row",
          classes.card
        )}
      >
        <div className="flex flex-col gap-2 sm:flex-row">
          <SchoolLogo
            alt="logo"
            className={clsx(classes.logo)}
            src={data.school.logo}
          />
          <div className="flex flex-col gap-1.75">
            <div>
              {(data.position || data.subject) && (
                <Typography
                  className={classes.jobTitle}
                  display="inline"
                  variant="strong"
                >
                  {data.position ?? ""}
                  {data.subject && `, ${data.subject.name}`}
                </Typography>
              )}
              {data.school.name && (
                <Typography variant="body">{data.school.name}</Typography>
              )}
            </div>
            {(data.school.curriculumBoard.length ||
              (data.school.state && data.school.country)) && (
              <div className="flex flex-wrap gap-x-1 gap-y-2">
                {data.school.curriculumBoard.map((board, index) => (
                  <Tag key={index} label={board.name} size="sm" />
                ))}
                {data.school.state && data.school.country && (
                  <Tag
                    color="onSurface"
                    label={`${data.school.state.name}, ${data.school.country.name}`}
                    size="sm"
                  />
                )}
              </div>
            )}
            {data.salaryNegotiable ? (
              <Typography variant="strong">Salary Negotiable</Typography>
            ) : (
              data.salaryOffered &&
              data.currency &&
              data.salaryOffered > 0 && (
                <div className="flex flex-wrap items-center">
                  <Typography variant="strong">
                    {data.currency.code} {data.salaryOffered.toLocaleString()}
                    {data.maximumSalaryOffered ? (
                      <> - {data.maximumSalaryOffered.toLocaleString()}</>
                    ) : null}
                  </Typography>
                  <Typography color="onSurface.500" variant="body">
                    /year
                  </Typography>
                </div>
              )
            )}
          </div>
        </div>
        <div className="flex items-end justify-between sm:flex-col">
          {getStatus()}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="flex items-center gap-1.25"
            /**
             * This onClick is important. We need this to prevent react-modal from firing the top level onClick which contains navigate().
             * If we remove this then a bug is introduced that fires the root onClick when you close the sharesheet.
             */
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <ShareJobButton
              activator={handleOpen => (
                <IconButton
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation()
                    handleOpen()
                  }}
                >
                  <ShareAndroid />
                </IconButton>
              )}
              jobSlug={data.slug}
              position={data.position}
              schoolProfile={data.school}
              subject={data.subject.name}
            />

            {getCTA()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
