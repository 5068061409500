import { TeachingMode } from "api/resources/schools/types"

export const RTL_LANGUAGE_CODES = ["ar"]

export const DEFAULT_LANGUAGE_CODE = "en"
export const DEFAULT_GALLERY_PIC = "/assets/defaultGalleryImage.jpg"

export const PAGE_SIZE = 10
export const USER_TYPE = "Student"

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"

export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
}

export enum ServicePrefix {
  nebula = "nebula",
  solis = "solis",
  gravity = "gravity",
  doppler = "doppler",
}

export const tabsProfile = {
  overview: "Overview",
  gallery: "Gallery",
  jobs: "Jobs",
  perksAndAmenities: "Perks and Amenities",
}
export const weekDays = [
  { id: 7, day: "Sunday" },
  { id: 1, day: "Monday" },
  { id: 2, day: "Tuesday" },
  { id: 3, day: "Wednesday" },
  { id: 4, day: "Thursday" },
  { id: 5, day: "Friday" },
  { id: 6, day: "Saturday" },
]

export const teachingModeChoices = [
  { label: "Online", value: TeachingMode.ONLINE },
  {
    label: "Offline",
    value: TeachingMode.OFFLINE,
  },
  {
    label: "Blended (Online & Offline)",
    value: TeachingMode.HYBRID,
  },
]

export enum ModeOfLearning {
  ONLINE = "online",
  CLASSROOM = "classroom",
  BLENDED = "blended",
}

export const modeOfLearningChoices = [
  { label: "Online", value: ModeOfLearning.ONLINE },
  {
    label: "Classroom Programme (offline)",
    value: ModeOfLearning.CLASSROOM,
  },
  {
    label: "Blended (Online & Classroom)",
    value: ModeOfLearning.BLENDED,
  },
]

export const SCHOOL_PLACEHOLDER_LOGO = "/assets/schoolLogo.svg"

export type NotificationAction =
  | {
      name: "JOB_OFFER_RECEIVED"
      data: {
        jobOfferId: number
      }
    }
  | {
      name:
        | "INTERVIEW_SCHEDULED"
        | "LIVE_DEMO_SCHEDULED"
        | "SCHOOL_ASSESSMENT_SCHEDULED"
      data: {
        jobId: number
        jobApplicant: number
        jobApplicantStepId: number
      }
    }
  | {
      name: "INVITE_RECEIVED"
      data: { jobApplicantId: number }
    }
  | {
      name: "JOB_PUBLISHED"
      data: {
        jobSlug: string
        schoolSlug: string
      }
    }

export const isLocalhost = import.meta.env.DEV
