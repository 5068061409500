import {
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Job,
  JobInterestedUserStatus,
  JobInvitedUserStatus,
} from "api/resources/jobs/types"
import clsx from "clsx"
import ShareJobButton from "components/jobs/ShareJobButton"
import ProfileCompletion from "components/ProfileCompletion"
import AsyncBuilder from "components/shared/AsyncBuilder"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { Check, ShareAndroid } from "iconoir-react"
import React, { useContext } from "react"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"
import { getAuthInfo } from "utils/auth"
import { generateProfileCompletionURL, isProfileComplete } from "utils/helpers"
import useEnrollments from "utils/hooks/useEnrollments"
import useToggle from "utils/hooks/useToggle"
import { routes } from "utils/routes"
import toast from "utils/toast"
import SchoolProfileContext from "views/schoolProfile/context"

const useStyles = createUseStyles(theme => ({
  container: {
    borderRadius: theme.spacing(1),
    width: "100%",
    border: `1px solid ${theme.colors.surface[200]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
  bottomContainer: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
  },
}))

function JobCard({
  position,
  salaryOffered,
  maximumSalaryOffered,
  subject,
  datePublished,
  currency,
  jobApplicant,
  id,
  eligibilityCriterion,
  slug,
  salaryNegotiable,
  jobInterestedUser,
  jobInvitedUser,
  onApply,
}: Job & { onApply: () => void }) {
  const classes = useStyles()
  const { profile } = useContext(SchoolProfileContext)
  const authInfo = getAuthInfo()

  const [searchParams] = useSearchParams()
  const jobSlug = searchParams.get("jobSlug")

  const [profileCompletionDialog, toggleProfileCompletionDialog] =
    useToggle(false)
  const { stepsOverview } = useContext(GlobalContext)

  const applyForJob = async (applyJobId?: string) => {
    const res = await api.jobs.jobApplicant.create({
      urlParams: { jobId: applyJobId ?? id },
    })

    if (res.isSuccessful) {
      toast.success("Applied for Job Successfully")
      /**
       * JobOverviewCard which is displayed on top
       * has the same job as one of the JobCard
       * we want to keep the `Applied` state in sync
       * when user applies through either of the cards
       */
      if (slug === jobSlug) {
        return window.location.reload()
      }
      onApply()
    } else toast.error(res.errors.message || "Something went wrong")
  }

  const handleApplyForJob = async () => {
    if (!authInfo) {
      const href = generateProfileCompletionURL({
        jobId: id.toString(),
      })
      if (href) {
        return (window.location.href = href)
      }
    } else if (!isProfileComplete(stepsOverview)) {
      toggleProfileCompletionDialog()
    } else {
      await applyForJob()
    }
  }

  const handleRegisterInterest = async () => {
    if (!authInfo) {
      const href = generateProfileCompletionURL({
        jobId: id.toString(),
        ri: "true",
      })
      if (href) {
        return (window.location.href = href)
      }
    } else if (!isProfileComplete(stepsOverview)) {
      toggleProfileCompletionDialog()
    } else {
      await registerInterest()
    }
  }

  const { isPgCTLUser } = useEnrollments()

  const registerInterest = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault()

    const res = await api.jobs.interestedUser.create({
      urlParams: {
        jobId: id,
      },
    })
    if (res.isSuccessful) {
      window.location.reload()
      toast.success(
        "Great! Thank you for expressing interest in this job. Our placements team will be in touch with you"
      )
    } else {
      toast.error("There was an issue while processing your request")
    }
  }

  const getAction = () => {
    const registerInterestButton = (
      <div>
        <Tooltip
          disabled={isPgCTLUser}
          title="This job is only for Suraasa's PgCTL Learners"
        >
          <div>
            <AsyncBuilder
              handler={handleRegisterInterest}
              render={({ onClick, loading }) => (
                <Button
                  disabled={!isPgCTLUser}
                  loading={loading}
                  size="sm"
                  variant="outlined"
                  onClick={onClick}
                >
                  Register Interest
                </Button>
              )}
            />
          </div>
        </Tooltip>
      </div>
    )
    if (jobInterestedUser) {
      if (jobInterestedUser.status === JobInterestedUserStatus.PENDING) {
        return (
          <span>
            <Tooltip
              maxWidth="200px"
              title="Our placements team will be in touch with you"
            >
              <div>
                <Chip
                  color="success"
                  label="Applied"
                  size="sm"
                  startAdornment={<Check strokeWidth={3} />}
                />
              </div>
            </Tooltip>
          </span>
        )
      }
    }
    if (jobInvitedUser) {
      if (jobInvitedUser.status === JobInvitedUserStatus.PENDING) {
        return (
          <Button
            component={Link}
            size="sm"
            to={`${routes.home}?tab=Pending+Applications`}
            variant="outlined"
          >
            View Invitation
          </Button>
        )
      }
    }

    if (jobApplicant) {
      return (
        <Button
          component={Link}
          size="sm"
          to={`${routes.jobTimeline
            .replace(":jobId", String(id))
            .replace(":jobApplicant", jobApplicant.id.toString())}`}
        >
          View Application
        </Button>
      )
    } else {
      if (eligibilityCriterion === "qualification") {
        return registerInterestButton
      }
      return (
        <Button
          disabled={authInfo ? stepsOverview.loading : false}
          variant="filled"
          onClick={handleApplyForJob}
        >
          Apply Now
        </Button>
      )
    }
  }

  return (
    <>
      {stepsOverview && profileCompletionDialog && (
        <ProfileCompletion
          jobId={id}
          open={profileCompletionDialog}
          toggle={toggleProfileCompletionDialog}
        />
      )}

      <div className={clsx("flex flex-col justify-between", classes.container)}>
        <div className="flex justify-between p-2">
          <div className="flex flex-col gap-0.5">
            <Typography variant="strong">{position}</Typography>
            {salaryNegotiable && <Typography>Salary Negotiable</Typography>}
            {currency && salaryOffered && (
              <div className="flex flex-wrap items-center">
                <Typography variant="strong">
                  {currency.code} {salaryOffered.toLocaleString()}
                  {maximumSalaryOffered ? (
                    <> - {maximumSalaryOffered.toLocaleString()}</>
                  ) : null}
                </Typography>
                <Typography color="onSurface.500" variant="body">
                  /year
                </Typography>
              </div>
            )}
            <Typography color="onSurface.500" variant="body">
              {subject.name}
            </Typography>
          </div>

          <ShareJobButton
            activator={handleOpen => (
              <IconButton
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation()
                  handleOpen()
                }}
              >
                <ShareAndroid />
              </IconButton>
            )}
            jobSlug={slug}
            position={position}
            schoolProfile={profile}
            subject={subject.name}
          />
        </div>
        <div
          className={clsx(
            "flex items-center justify-between px-2 py-1",
            classes.bottomContainer
          )}
        >
          <Typography color="onSurface.500" variant="smallBody">
            {datePublished &&
              `Posted on ${format(new Date(datePublished), "do MMM yyyy")}`}
          </Typography>
          {getAction()}
        </div>
      </div>
    </>
  )
}

export default JobCard
