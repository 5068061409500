import { useContext } from "react"

import { GlobalContext } from "GlobalState"

import { learningItemSlugs } from "utils/helpers"

const useEnrollments = () => {
  const { enrollments } = useContext(GlobalContext)

  const isPgCTLUser = (() =>
    enrollments.some(
      enrollment => enrollment.slug === learningItemSlugs.pgctl
    ))()

  return {
    isPgCTLUser,
  }
}

export default useEnrollments
