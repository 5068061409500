import "./index.css"

import * as Sentry from "@sentry/react"
import ErrorScreen from "components/ErrorScreen"
import React from "react"
import { createRoot } from "react-dom/client"
import { getAuthInfo } from "utils/auth"

import { version } from "../package.json"
import App from "./App"

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
  })

  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

if (!import.meta.env.VITE_SSO_URL) {
  throw new Error("Please add VITE_SSO_URL in your environment variables.")
}

const container = createRoot(document.getElementById("root") as HTMLElement)

container.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Sentry.ErrorBoundary>
)
