import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getNebulaURL = getServiceURL(ServicePrefix.nebula)

export const urls = validateUrls({
  jobs: {
    list: () => getNebulaURL(`/v1/jobs/`),
    overview: () => getNebulaURL(`/v1/jobs/overview/`),
    detail: id => getNebulaURL(`/v1/jobs/${id}/`),
  },
  jobApplicant: {
    create: jobId => getNebulaURL(`/v1/jobs/${jobId}/interested-user/`),
    markInvitedAsSeen: () =>
      getNebulaURL(`/v1/jobs/invited-users/bulk-update/`),
    update: jobApplicantId =>
      getNebulaURL(`/v1/jobs/applicants/${jobApplicantId}/`),
    listActive: () => getNebulaURL(`/v1/jobs/applications/`),
    // listInvited: jobId => `/jobs/${jobId}/applicants/invited/`,
    list: () => getNebulaURL(`/v1/jobs/applicants/`),
    listPendingApplications: () =>
      getNebulaURL(`/v1/jobs/pending-applications/`),
    updateInviteStatus: userId =>
      getNebulaURL(`/v1/jobs/invited-user/${userId}/`),
    updateJobInterestedUser: jobId =>
      getNebulaURL(`/v1/jobs/interested-user/${jobId}/`),
  },
  jobOffers: {
    list: () => getNebulaURL("/v1/hiring/offers/"),
    markAsSeen: () => getNebulaURL("/v1/hiring/offers/mark-as-viewed/"),
    retrieve: jobOfferId => getNebulaURL(`/v1/hiring/offers/${jobOfferId}/`),
    verifyOtp: jobOfferId => getNebulaURL(`/v1/hiring/offers/${jobOfferId}/`),
    generateOtp: jobOfferId =>
      getNebulaURL(`/v1/hiring/offers/${jobOfferId}/otp/`),
  },
  jobApplicantStep: {
    list: jobApplicantId =>
      getNebulaURL(`/v1/hiring/applicants/${jobApplicantId}/steps/`),
  },
  marketingData: {
    submit: () => "/jobs/users/marketing-form/",
  },
  stepsOverview: {
    list: () => "/jobs/users/marketing-form/overview/",
  },
  interestedUser: {
    create: jobId => getNebulaURL(`/v1/jobs/${jobId}/interested-user/`),
  },
})
