import { Dialog, IconButton, theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
import React from "react"
import { createUseStyles } from "react-jss"

import CopyText from "./CopyText"
import SocialIcons, { Props as SocialIconsProps } from "./SocialIcons"

const animationDuration = 225
const useStyles = createUseStyles({
  "@keyframes slideXs": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(0),
    },
  },
  "@keyframes slideSm": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(3),
    },
  },
  "@keyframes slideLg": {
    "0%": {
      bottom: "-100%",
    },
    "100%": {
      bottom: theme.spacing(4),
    },
  },

  shareDialog: {
    padding: theme.spacing(4, 2.5),
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 10,
    width: "100% !important",
    background: "#fff",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: theme.spacing(0),
    animation: `$slideXs ${animationDuration}ms`,
    [theme.breakpoints.up("sm")]: {
      borderRadius: theme.spacing(1),
      width: "calc(100% - 80px) !important",
      left: theme.spacing(5),
      bottom: theme.spacing(3),
      animation: `$slideSm ${animationDuration}ms`,
      padding: theme.spacing(4, 6),
    },

    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 160px) !important",
      left: theme.spacing(10),
    },

    [theme.breakpoints.up("lg")]: {
      width: "calc(100% - 448px) !important",
      left: theme.spacing(28),
      animation: `$slideLg ${animationDuration}ms`,
      bottom: theme.spacing(4),
    },

    [theme.breakpoints.up("xl")]: {
      width: "50% !important",
      left: "25%",
    },
  },

  cancelButton: {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },

  width100: {
    width: "100%",
  },
})

export type Props = {
  open: boolean
  handleClose: () => void
} & Pick<SocialIconsProps, "data">

const ShareSheet = ({
  open,
  handleClose,
  data,
  classes: classOverrides,
}: Props & {
  classes?: {
    socialIconsRoot?: string
  }
}) => {
  const classes = useStyles()

  return (
    <>
      <Dialog
        animation="none"
        className={classes.shareDialog}
        open={open}
        onRequestClose={() => handleClose()}
      >
        <div
          className={clsx(
            "mb-2 flex items-center justify-between sm:mb-4",
            classes.width100
          )}
        >
          <Typography display="block" variant="title1">
            Share via
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.cancelButton}
            color="black"
            size="sm"
            onClick={() => handleClose()}
          >
            <Xmark height="24px" width="24px" />
          </IconButton>
        </div>

        <SocialIcons className={classOverrides?.socialIconsRoot} data={data} />

        <Typography className="mb-1" variant="subtitle2">
          Or copy link
        </Typography>

        <CopyText url={data.url} />
      </Dialog>
    </>
  )
}

export default ShareSheet
