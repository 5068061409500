import {
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { ActiveJobOverview } from "api/resources/jobs/types"
import ActiveApplicationsTab from "components/home/ActiveApplicationsTab"
import CompleteProfile from "components/home/CompleteProfile"
import JobOffersTab from "components/home/JobOffersTab"
import PendingApplicationsTab from "components/home/PendingApplicationsTab"
import Navbar from "components/shared/Navbar"
import ReactHelmet from "components/shared/ReactHelmet"
import Tabs from "components/Tab/Tabs"
import { GlobalContext } from "GlobalState"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { isProfileComplete } from "utils/helpers"
import useTabs, { Tabs as TabsType } from "utils/hooks/useTabs"
import { routes } from "utils/routes"

import { TabContext } from "./TabContext"

const useStyles = createUseStyles(theme => ({
  notificationPill: {
    background: theme.colors.critical[500],
    color: theme.colors.common.white[500],
    borderRadius: "50%",
    height: "8px",
    width: "8px",
    marginTop: theme.spacing(-1),
  },
}))

enum TABS {
  active = "Active Applications",
  pending = "Pending Applications",
  jobOffers = "Job Offers",
}

const initialTabs: TabsType = [
  {
    name: TABS.active,
    content: <Typography>{TABS.active}</Typography>,
  },
  {
    name: TABS.pending,
    content: <Typography>{TABS.pending}</Typography>,
  },
  {
    name: TABS.jobOffers,
    content: <Typography>{TABS.jobOffers}</Typography>,
  },
]

const Home = () => {
  const [allTabs, setAllTabs] = useState(initialTabs)
  const [currentTab, setCurrentTab] = useTabs({ tabs: allTabs })
  const [renderedTab, setRenderedTab] = useState<JSX.Element>()

  const [overview, setOverview] = useState<ActiveJobOverview>({
    newInvites: 0,
    newOffers: 0,
    totalActiveApplications: 0,
    totalApplied: 0,
    totalInvites: 0,
    totalJobOffers: 0,
  })
  const { stepsOverview } = useContext(GlobalContext)

  const classes = useStyles()

  useEffect(() => {
    const fetchOverview = async () => {
      const res = await api.jobs.overview()
      if (res.isSuccessful) {
        if ("newInvites" in res.data) setOverview(res.data)
      }
    }
    fetchOverview()
  }, [])

  useEffect(() => {
    setAllTabs(tabs =>
      tabs.map(tab => {
        if (tab.name === TABS.pending) {
          if (overview.totalInvites + overview.totalApplied > 0)
            tab.content = (
              <div className="flex items-center gap-0.5">
                <Typography>{`${TABS.pending} (${
                  overview.totalInvites + overview.totalApplied
                })`}</Typography>
                {overview.newInvites > 0 && (
                  <span className={classes.notificationPill} />
                )}
              </div>
            )
          else tab.content = <Typography>{TABS.pending}</Typography>
          return tab
        }
        if (tab.name === TABS.jobOffers) {
          if (overview.totalJobOffers > 0)
            tab.content = (
              <div className="flex items-center gap-0.5">
                <Typography>{`${TABS.jobOffers} (${overview.totalJobOffers})`}</Typography>
                {overview.newOffers > 0 && (
                  <span className={classes.notificationPill} />
                )}
              </div>
            )
          else tab.content = <Typography>{TABS.jobOffers}</Typography>
          return tab
        }
        if (tab.name === TABS.active) {
          if (overview.totalActiveApplications > 0)
            tab.content = (
              <div className="flex items-center gap-0.5">
                <Typography>{`${TABS.active} (${overview.totalActiveApplications})`}</Typography>
              </div>
            )
          else tab.content = <Typography>{TABS.active}</Typography>
          return tab
        }

        return tab
      })
    )
  }, [overview, classes.notificationPill])

  useEffect(() => {
    switch (currentTab.name) {
      case TABS.active:
        setRenderedTab(<ActiveApplicationsTab />)
        break
      case TABS.pending:
        setRenderedTab(
          <PendingApplicationsTab
            updateNotifications={() =>
              setOverview(ov => ({
                ...ov,
                newInvites: 0,
              }))
            }
          />
        )
        break

      case TABS.jobOffers:
        setRenderedTab(
          <JobOffersTab
            updateNotifications={() =>
              setOverview(ov => ({
                ...ov,
                newOffers: 0,
              }))
            }
          />
        )
        break
      default:
    }
  }, [currentTab])

  if (stepsOverview.loading === true) {
    return (
      <>
        <Navbar gutterBottom={false} hideBackButton />
        <div className="flex items-center justify-center p-3">
          <CircularProgress />
        </div>
      </>
    )
  }

  return (
    <>
      <ReactHelmet
        data={{
          title: currentTab.name,
        }}
      />
      <TabContext.Provider value={{ overview, setOverview }}>
        <Navbar hideBackButton />

        <Container className="mb-3">
          {!isProfileComplete(stepsOverview) ? (
            <CompleteProfile />
          ) : (
            <>
              <div className="flex flex-wrap items-baseline justify-between gap-2">
                <Tabs
                  activeTab={currentTab}
                  tabs={allTabs}
                  onChange={setCurrentTab}
                />
                <Button component={Link} to={routes.explore}>
                  Explore Jobs
                </Button>
              </div>
              {renderedTab}
            </>
          )}
        </Container>
      </TabContext.Provider>
    </>
  )
}

export default Home
