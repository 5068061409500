import { Button, Divider, IconButton, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Qualification } from "api/resources/profile/types"
import { STEPS } from "components/ProfileCompletion/constants"
import Heading from "components/ProfileCompletion/Heading"
import { ProfileCompletionContext } from "components/ProfileCompletion/utils"
import LoadingOverlay from "components/shared/LoadingOverlay"
import SharedDialog from "components/SharedDialog"
import { format } from "date-fns"
import { GlobalContext } from "GlobalState"
import { ArrowRight, EditPencil, Plus, Trash } from "iconoir-react"
import { useContext, useEffect, useState } from "react"
import useArray from "utils/hooks/useArray"
import useToggle from "utils/hooks/useToggle"
import toast from "utils/toast"

import EditDialog from "./Qualifications/EditDialog"

const QualificationItem = ({
  name,
  endDate,
  startDate,
  qualificationLevel,
  organisationName,
  onEdit,
  onDelete,
}: Qualification & { onEdit: () => void; onDelete: () => void }) => (
  <div>
    <div className="flex items-center justify-between">
      <Typography variant="strong">{name}</Typography>
      <div className="-mt-1.25 flex">
        <IconButton color="primary" size="sm" onClick={onEdit}>
          <EditPencil />
        </IconButton>
        <IconButton color="critical" size="sm" onClick={onDelete}>
          <Trash />
        </IconButton>
      </div>
    </div>
    <Typography variant="body">{qualificationLevel.name}</Typography>
    <Typography variant="body">{organisationName}</Typography>
    <Typography variant="smallBody">
      {[
        format(new Date(startDate), "MMMM yyyy"),
        format(new Date(endDate), "MMMM yyyy"),
      ].join(" - ")}
    </Typography>
    <Divider className="mb-3 mt-2.5" color="onSurface.300" weight="light" />
  </div>
)

const AcademicDetails = () => {
  const [loading, setLoading] = useState(false)

  const { setStep } = useContext(ProfileCompletionContext)
  const { setStepsOverview } = useContext(GlobalContext)

  const [openDialog, toggleDialog] = useToggle(false)
  const [openRemoveDialog, toggleRemoveDialog] = useToggle(false)
  const [removeLoading, toggleRemoveLoading] = useToggle(false)

  const qualifications = useArray<Qualification>([])

  const [qualificationToEdit, setQualificationToEdit] = useState<
    Qualification["id"] | null
  >(null)
  const [qualificationToDelete, setQualificationToDelete] = useState<
    Qualification["id"] | null
  >(null)

  useEffect(() => {
    // Clear qualificationToEdit when dialog is closed
    if (qualificationToEdit && !openDialog) {
      setQualificationToEdit(null)
    }
  }, [qualificationToEdit, openDialog])

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const res = await api.profile.listAcademics({
        urlParams: { id: 1 },
      })
      if (res.isSuccessful) {
        qualifications.set(res.data.qualifications)
      } else {
        qualifications.set([])
      }
      setLoading(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRemove = async () => {
    if (!qualificationToDelete) return

    toggleRemoveLoading(true)
    const res = await api.profile.qualification.delete({
      urlParams: { id: qualificationToDelete },
    })

    if (res.isSuccessful) {
      qualifications.removeByKey(qualificationToDelete)
      toast.success("Removed successfully.")
      toggleRemoveDialog()
      setQualificationToDelete(null)
    } else if (res.errors.message) {
      toast.error(res.errors.message)
    }

    toggleRemoveLoading(false)
  }

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          <div className="flex flex-col" style={{ height: "100%" }}>
            <Heading
              subtitle="Let’s add qualification in your profile, it will help schools when they look at your profile."
              title="Academic Details"
            />
            {qualifications.array.length === 0 ? (
              <Button
                className="self-center md:self-start"
                onClick={() => toggleDialog()}
              >
                Add Qualification
              </Button>
            ) : (
              <div className="flex flex-col">
                <div className="mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <div className="col-span-1">
                      {qualifications.array.map(item => (
                        <QualificationItem
                          {...item}
                          key={item.id}
                          onDelete={() => {
                            setQualificationToDelete(item.id)
                            toggleRemoveDialog()
                          }}
                          onEdit={() => {
                            setQualificationToEdit(item.id)
                            toggleDialog()
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <Button
                    nudge="left"
                    startAdornment={<Plus />}
                    variant="text"
                    onClick={() => toggleDialog()}
                  >
                    Add Another Qualification
                  </Button>
                </div>
                <Button
                  className="mt-auto self-center md:self-start"
                  endAdornment={<ArrowRight />}
                  onClick={() => {
                    setStep(STEPS.UploadResume)
                  }}
                >
                  Continue
                </Button>
              </div>
            )}
          </div>

          <EditDialog
            id={qualificationToEdit}
            open={openDialog}
            qualifications={{
              data: qualifications.array,
              update: qualifications.updateByKey,
              remove: qualifications.removeByKey,
              add: qualifications.unshift,
            }}
            toggle={toggleDialog}
          />

          <SharedDialog
            handleClose={toggleRemoveDialog}
            loading={removeLoading}
            open={openRemoveDialog}
            title="Remove Qualification"
            isDestructive
            keepScrollLocked
            onConfirm={handleRemove}
          >
            Are you sure you want to remove{" "}
            <b>
              {
                qualifications.array.find(
                  item => item.id === qualificationToDelete
                )?.name
              }
            </b>{" "}
            from your profile?
          </SharedDialog>
        </>
      )}
    </>
  )
}

export default AcademicDetails
