import { Step, StepType } from "api/resources/jobs/types"
import AssessmentItem from "components/jobs/Timeline/AssessmentItem"
import InterviewItem from "components/jobs/Timeline/InterviewItem"
import LiveDemoItem from "components/jobs/Timeline/LiveDemoItem"
import TimelineContent from "components/Timeline/TimelineContent"
import React from "react"
import { getIcon, getTag } from "utils/getStepStatusTag"

const TimelineItem = (props: Step) => {
  const { stepType } = props
  return (
    // eslint-disable-next-line react/destructuring-assignment
    <TimelineContent icon={getIcon(props)} id={props.id.toString()}>
      {stepType === StepType.ASSESSMENT && (
        <AssessmentItem Tag={getTag(props)} {...props} />
      )}
      {stepType === StepType.INTERVIEW && (
        <InterviewItem Tag={getTag(props)} {...props} />
      )}
      {stepType === StepType.LIVE_DEMO && (
        <LiveDemoItem Tag={getTag(props)} {...props} />
      )}
    </TimelineContent>
  )
}

export default TimelineItem
