import { Menu, useMediaQuery } from "@suraasa/placebo-ui"
import { MenuButton } from "@szhsin/react-menu"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { NavLink } from "react-router-dom"

import { NavArrowDown } from "iconoir-react"

import "@szhsin/react-menu/dist/index.css"
import "@szhsin/react-menu/dist/transitions/slide.css"

const useStyles = createUseStyles(theme => ({
  link: {
    minWidth: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    padding: "6px 12px",
    position: "relative",
    color: theme.colors.onSurface[500],
    textDecoration: "none",
  },
  icon: {
    maxHeight: 42,
    transition: "all 0.2s linear",
  },
  activeIcon: {
    position: "absolute",
    top: "8px",
    maxHeight: 42,
    transition: "all 0.2s linear",
  },
  active: {
    transform: "scale(0)",
    opacity: 0,
  },
  menuWrapper: {
    // "& > ul $szh-menu": {
    //   width: 226,
    //   textDecoration: "none",
    // },

    "& div > ul > li > a ": {
      textDecoration: "none !important",
    },
  },
  isNotification: {
    "& > ul $szh-menu": {
      width: 420,
      padding: 0,
      borderRadius: 12,
      overflow: "hidden",
      textDecoration: "none",
    },
  },
  textFontFamily: {
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    fontWeight: 600,
    color: theme.colors.onSurface[500],
  },
  textActive: {
    color: theme.colors.primary[500],
  },
}))

type LinkProps = {
  icon: React.ReactNode
  activeIcon?: React.ReactElement
  text?: string
} & (
  | {
      href?: never
      isMenu: true
      arrow?: boolean
      menuChildren: React.ReactNode
      isNotification?: boolean
    }
  | {
      href: string
      isMenu?: false
      menuChildren?: never
      isNotification?: false
    }
)

const Link = ({
  menuChildren,
  icon,
  activeIcon,
  text,
  href,
  isNotification = false,
  ...props
}: LinkProps) => {
  const is2XS = useMediaQuery("@media only screen and (max-width: 340px)")
  const classes = useStyles()

  if (is2XS) {
    text = ""
  }

  if (props.isMenu) {
    if (props.arrow === undefined) {
      props.arrow = true
    }
    return (
      <div
        className={clsx(classes.menuWrapper, {
          [classes.isNotification]: isNotification,
        })}
      >
        <Menu
          menuButton={({ open }) => (
            <MenuButton
              className={clsx(classes.link, { [classes.textActive]: open })}
            >
              <div
                className={clsx(classes.activeIcon, {
                  [classes.active]: !open,
                })}
              >
                {activeIcon}
              </div>
              <div
                className={clsx(classes.icon, {
                  [classes.active]: open,
                })}
              >
                {icon}
              </div>
              {text && (
                <p
                  className={clsx("flex", classes.textFontFamily, {
                    [classes.textActive]: open,
                  })}
                >
                  <span className="mr-0.25">{text}</span>
                  {props.arrow && <NavArrowDown />}
                </p>
              )}
            </MenuButton>
          )}
        >
          {menuChildren}
        </Menu>
      </div>
    )
  }

  if (!href) throw new Error("Link supplied without href")
  return (
    <NavLink
      style={{
        textDecoration: "none",
        border: 0,
      }}
      to={href}
    >
      {({ isActive }) => (
        <div className={clsx(classes.link, { [classes.textActive]: isActive })}>
          <div
            className={clsx(classes.activeIcon, {
              [classes.active]: !isActive,
            })}
          >
            {activeIcon}
          </div>
          <div
            className={clsx(classes.icon, {
              [classes.active]: isActive,
            })}
          >
            {icon}
          </div>
          {text && (
            <p
              className={clsx(classes.textFontFamily, {
                [classes.textActive]: isActive,
              })}
            >
              {text}
            </p>
          )}
        </div>
      )}
    </NavLink>
  )
}

export default Link
