import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatPaginatedResponse,
  formatSuccessResponse,
} from "api/utils"

import {
  ActiveApplication,
  Application,
  InviteUpdateResponse,
  Job,
  JobOfferDetailsType,
  JobOfferType,
  JobOverview,
  Overview,
  PendingApplication,
  Step,
  StepsOverview,
  WithdrawApplicationResponse,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({ params }: Config): Promise<PaginatedAPIResponse<Job[]>> => {
    try {
      const res = await axios.get(urls.jobs.list(), {
        params,
      })
      return formatPaginatedResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  retrieve: async ({
    urlParams: { jobId },
  }: ConfigWithURLParams): Promise<APIResponse<Job>> => {
    try {
      const res = await axios.get(urls.jobs.detail(jobId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  overview: async (): Promise<APIResponse<Overview>> => {
    try {
      const res = await axios.get(urls.jobs.overview())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  jobOffers: {
    list: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<JobOfferType[]>> => {
      try {
        const res = await axios.get(urls.jobOffers.list(), {
          params: {
            fields: ["school", "date_created", "is_viewed"],
            ...params,
          },
        })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    retrieve: async ({
      params,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<JobOfferDetailsType>> => {
      try {
        const res = await axios.get(
          urls.jobOffers.retrieve(urlParams.jobOfferId),
          params
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    generateOtp: async ({
      urlParams: { jobOfferId },
    }: ConfigWithURLParams): Promise<APIResponse<{ resendAt: string }>> => {
      try {
        const res = await axios.post(urls.jobOffers.generateOtp(jobOfferId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    verifyOtp: async ({
      urlParams: { jobOfferId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<JobOverview>> => {
      try {
        const res = await axios.put(urls.jobOffers.verifyOtp(jobOfferId), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    markAsSeen: async ({
      data,
    }: Config): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(urls.jobOffers.markAsSeen(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicant: {
    create: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.jobApplicant.create(urlParams.jobId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    withdraw: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<
      APIResponse<WithdrawApplicationResponse>
    > => {
      try {
        const res = await axios.put(
          urls.jobApplicant.update(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateJobInterestedUser: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.updateJobInterestedUser(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    delete: async ({
      urlParams: { jobApplicantId },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.jobApplicant.update(jobApplicantId))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateInviteStatus: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<InviteUpdateResponse>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.updateInviteStatus(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      urlParams: { jobApplicantId },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<Step[]>> => {
      try {
        const res = await axios.put(
          urls.jobApplicant.update(jobApplicantId),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listActive: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ActiveApplication[]>> => {
      try {
        const res = await axios.get(urls.jobApplicant.listActive(), {
          params,
        })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<Application[]>> => {
      try {
        const res = await axios.get(urls.jobApplicant.list(), {
          params,
        })
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listPendingApplications: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<PendingApplication[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicant.listPendingApplications(),
          {
            params,
          }
        )
        return formatPaginatedResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    markInvitedAsSeen: async ({
      data,
    }: Config): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(urls.jobApplicant.markInvitedAsSeen(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },

  jobApplicantStep: {
    list: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Step[]>> => {
      try {
        const res = await axios.get(
          urls.jobApplicantStep.list(urlParams.jobApplicantId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  submitMarketingData: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.marketingData.submit(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  stepsOverview: async (): Promise<APIResponse<StepsOverview>> => {
    try {
      const res = await axios.get(urls.stepsOverview.list())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  interestedUser: {
    create: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.interestedUser.create(urlParams.jobId)
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
