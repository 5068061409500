import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@suraasa/placebo-ui"
import BookClosed from "assets/navigation/BookClosed"
import HomeLine from "assets/navigation/HomeLine"
import clsx from "clsx"
import {
  BoxIso,
  Calendar,
  HeadsetHelp,
  Journal,
  LogOut,
  Medal,
  MediaVideoList,
  MicrophoneSpeaking,
  NavArrowDown,
  Settings,
  Shop,
  ShoppingBag,
  Suitcase,
  ViewGrid,
  Xmark,
} from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link } from "react-router-dom"
import { CSSTransition } from "react-transition-group"
import { getAuthInfo } from "utils/auth"
import { buildUserName, getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"

const duration = 225
const sideBarWidth = 300
const cubicBezier = "cubic-bezier(0, 0, 0.2, 1) 0ms"
const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    position: "absolute",
    left: -sideBarWidth,
    top: "0px",
    height: "100vh",
    width: sideBarWidth,
    zIndex: theme.zIndex.navbar,
    transition: `left ${duration}ms ${cubicBezier}`,
    willChange: "left",
    overflowY: "auto",
    overscrollBehavior: "none",
    "&.enter-active": { left: 0 },
    "&.enter-done": { left: 0 },
    "&.exit-active": { left: -sideBarWidth },
    "&.exit-done": { left: -sideBarWidth },
  },

  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  backdrop: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    zIndex: theme.zIndex.navbar - 1,
    top: 0,
    backgroundColor: "transparent",
    backdropFilter: "blur(0px)",
    transition: `background-color backdrop-filter ${duration}ms ${cubicBezier}`,
    willChange: "background-color backdrop-filter",

    "&.enter-active": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.enter-done": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.exit-active": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
    "&.exit-done": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
  },

  arrowDown: {
    transform: "rotate(0deg)",
    transition: "transform 0.5s",
  },
  arrowDownActive: {
    transform: "rotate(180deg)",
  },

  termsConditionsButton: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  moreMenuItem: {
    display: "grid",
    gridTemplateRows: "0fr",
    transition: "all 0.5s",
  },
  moreMenuItemActive: {
    gridTemplateRows: "1fr",
  },
  bottomBorder: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.onSurface[200],
  },
  isDisabled: {
    color: theme.colors.onSurface[500],
  },
  isCritical: {
    color: theme.colors.critical[500],
  },
}))

type Props = {
  open: boolean
  handleLogout: () => void
  handleClose: () => void
}

type MenuItem = {
  name: string
  icon: any
  endIcon?: JSX.Element
  bottomBorder?: boolean
  disabled?: boolean
} & ({ href: string } | { key: "more" | "sign_out" })

const menuItems: MenuItem[] = [
  {
    name: "Home",
    href: getPlatformURL("learn", "/home"),
    icon: HomeLine,
  },
  {
    name: "Learning",
    href: getPlatformURL("learn", "/library"),
    icon: BookClosed,
  },
  {
    name: "Jobs",
    href: routes.home,
    icon: Suitcase,
  },
  // {
  //   name: "Notification",
  //   href: getPlatformURL("suraasa", "/notification"),
  //   icon: Bell,
  //   bottomBorder: true,
  // },
  {
    name: "My Certificates",
    href: getPlatformURL("learn", "/certificates"),
    icon: Medal,
  },
  {
    name: "My Schedule",
    href: getPlatformURL("learn", "/my-schedule"),
    icon: Calendar,
    bottomBorder: true,
  },
  {
    name: "Help Centre",
    disabled: false,
    href: getPlatformURL("sso", "/help"),
    icon: HeadsetHelp,
  },
  {
    name: "Settings",
    href: getPlatformURL("learn", "/settings"),
    icon: Settings,
  },
  {
    name: "Sign Out",
    key: "sign_out",
    icon: LogOut,
    bottomBorder: true,
  },
  {
    name: "More",
    key: "more",
    icon: ViewGrid,
  },
]

const moreMenuItems: MenuItem[] = [
  {
    name: "Store",
    href: getPlatformURL("suraasa", "/store"),
    icon: Shop,
  },
  {
    name: "Masterclasses",
    href: getPlatformURL("suraasa", "/masterclasses"),
    icon: MediaVideoList,
  },
  {
    name: "Blogs",
    href: getPlatformURL("suraasa", "/blog"),
    icon: Journal,
  },
  {
    name: "Podcasts",
    href: "https://podcasters.spotify.com/pod/show/suraasa",
    icon: MicrophoneSpeaking,
  },
]

const svgProps = {
  width: 20,
  height: 20,
}

const SidebarMenuItem = ({
  item,
  handleAction,
}: {
  item: MenuItem
  handleAction: () => void
}) => {
  const classes = useStyles()
  const display = (
    <div
      className={clsx("flex items-center gap-1 py-2", {
        [classes.bottomBorder]: item.bottomBorder,
        [classes.isDisabled]: item.disabled,
        [classes.isCritical]: ("key" in item && item.key) === "sign_out",
      })}
      style={{
        width: "100%",
        flexGrow: 1,
      }}
    >
      <item.icon {...svgProps} />
      <Typography textAlign="left" variant="subtitle2">
        {item.name}
      </Typography>
    </div>
  )
  if ("href" in item && !item.disabled) {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        style={{
          textDecoration: "none",
          color: "initial",
        }}
        to={item.href}
        onClick={handleAction}
      >
        {display}
      </Link>
    )
  }

  return (
    <button
      className="flex items-center justify-between"
      disabled={item.disabled}
      onClick={handleAction}
    >
      {display}
      {item.endIcon && item.endIcon}
    </button>
  )
}

const Sidebar = ({ open, handleLogout, handleClose }: Props) => {
  const classes = useStyles()
  const [showMore, setShowMore] = useState(false)

  const authInfo = getAuthInfo()
  const userName = authInfo ? buildUserName(authInfo.user) : ""

  useEffect(() => {
    function handleEsc(e: KeyboardEvent) {
      if (e.key === "Escape") {
        handleClose()
      }
    }
    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [handleClose])

  const handleAction = (item: MenuItem) => {
    if ("key" in item) {
      switch (item.key) {
        case "more":
          setShowMore(prevState => !prevState)
          return
        case "sign_out":
          handleLogout()
          return
        default:
          throw new Error("Action not handled in profile sidebar")
      }
    }
  }

  const getEndIcon = (item: MenuItem) => {
    if ("key" in item) {
      if (item.key === "more") {
        return (
          <div
            className={clsx(classes.arrowDown, {
              [classes.arrowDownActive]: showMore,
            })}
          >
            <NavArrowDown />
          </div>
        )
      }
    }
  }

  return (
    <>
      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <div className={clsx(classes.content, "p-2")}>
            {authInfo && (
              <div className="flex flex-col">
                <div className="flex items-start justify-between gap-4.5 pb-2">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "initial",
                    }}
                    to={getPlatformURL("suraasa", "/profile")}
                    onClick={handleClose}
                  >
                    <div className="flex">
                      <div
                        style={{
                          flexShrink: 0,
                        }}
                      >
                        <Avatar
                          name={userName}
                          size="sm"
                          src={authInfo.user?.photo}
                        />
                      </div>
                      <div className="ml-1">
                        <Typography
                          style={{
                            wordBreak: "break-word",
                            whiteSpace: "pre-wrap",
                          }}
                          variant="strong"
                        >
                          {userName}
                        </Typography>
                        <Typography color="onSurface.500" variant="smallBody">
                          View Profile
                        </Typography>
                      </div>
                    </div>
                  </Link>
                  <IconButton color="black" onClick={handleClose}>
                    <Xmark />
                  </IconButton>
                </div>

                <Divider color="onSurface.200" weight="light" />

                <div className="flex flex-col">
                  {menuItems.map((item, index) => (
                    <SidebarMenuItem
                      handleAction={() => {
                        if ("href" in item && !item.disabled) {
                          handleClose()
                        } else {
                          handleAction(item)
                        }
                      }}
                      item={{ ...item, endIcon: getEndIcon(item) }}
                      key={index}
                    />
                  ))}

                  <div
                    className={clsx(classes.moreMenuItem, {
                      [classes.moreMenuItemActive]: showMore,
                    })}
                  >
                    <div
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      {moreMenuItems.map((item, index) => (
                        <SidebarMenuItem
                          handleAction={() => {
                            if ("href" in item && !item.disabled) {
                              handleClose()
                            } else {
                              handleAction(item)
                            }
                          }}
                          item={item}
                          key={index}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col">
              <Button
                className={clsx(classes.termsConditionsButton)}
                component="a"
                href={getPlatformURL("suraasa", "/privacy-policy")}
                variant="text"
              >
                <Typography color="onSurface.400" variant="smallBody">
                  Privacy Policy
                </Typography>
              </Button>
              <Button
                className={classes.termsConditionsButton}
                component="a"
                href={getPlatformURL("suraasa", "/terms-of-use")}
                variant="text"
              >
                <Typography color="onSurface.400" variant="smallBody">
                  Terms and Conditions
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div
          className={classes.backdrop}
          role="presentation"
          onClick={handleClose}
        />
      </CSSTransition>
    </>
  )
}

export default Sidebar
