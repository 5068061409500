import {
  Button,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tag,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  ActionBy,
  JobApplicantStatus,
  PendingApplication,
  Reason,
} from "api/resources/jobs/types"
import { PaginatedResponse } from "api/types"
import clsx from "clsx"
import AcceptApplicationDialog from "components/jobs/AcceptApplicationDialog"
import RejectApplicationDialog from "components/jobs/RejectApplicationDialog"
import NoDataCard from "components/shared/NoDataCard"
import SchoolLogo from "components/shared/SchoolLogo"
import { format } from "date-fns"
import differenceInCalendarDays from "date-fns/differenceInCalendarDays"
import isToday from "date-fns/isToday"
import { ArrowDown, ArrowUp, NavArrowDown } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { Link, useSearchParams } from "react-router-dom"
import { highlightElement } from "utils/helpers"
import { routes } from "utils/routes"
import toast from "utils/toast"
import { TabContext } from "views/Home/TabContext"

const useStyles = createUseStyles(theme => ({
  root: {
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
    },
  },
  container: {
    background: "white",
    borderRadius: "4px",
    border: `1px solid ${theme.colors.surface[200]}`,
    minWidth: "800px",
  },

  tableGridHeader: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "70px 1.75fr 1.75fr 1fr 1fr 1fr",
  },

  tableGrid: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "70px 1.75fr 1fr 1fr 1fr",
  },

  tableItem: {
    borderTop: `1px solid ${theme.colors.surface[200]}`,
    position: "relative",
  },

  image: {
    width: "32px",
    height: "32px",
  },

  textSmall: {
    fontSize: "12px",
    lineHeight: "15.6px",
  },
}))
enum ApplicationSource {
  JOB_INVITED_USER = "job_invited_user",
  JOB_INTERESTED_USER = "job_interested_user",
}

enum ApplicationType {
  ALL = "all",
  INVITES = "invited",
  APPLIED = "applied",
}

const PendingApplicationsTab = ({
  updateNotifications,
}: {
  updateNotifications: () => void
}) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)

  const [dateOrderingRecent, setDateOrderingRecent] = useState(true)

  const { setOverview } = useContext(TabContext)

  const [dialog, setDialog] = useState<{
    mode: "withdraw" | "reject" | "accept"
    actionItem: PendingApplication
  } | null>(null)

  const [applicationType, setApplicationType] = useState<ApplicationType>(
    ApplicationType.ALL
  )

  const [applications, setApplications] = useState<
    PaginatedResponse<PendingApplication[]>["data"]
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const inviteId = searchParams.get("inviteId")

  useEffect(() => {
    if (inviteId && applications.data.length > 0 && !loading) {
      highlightElement({
        element: document.getElementById(inviteId),
        onEnd: () => {
          searchParams.delete("inviteId")
          setSearchParams(searchParams, { replace: true })
        },
      })
    }
  }, [inviteId, applications, loading, searchParams, setSearchParams])

  useEffect(() => {
    const timer = window.setTimeout(async () => {
      const invites = applications.data.map(application => application.id)

      if (invites.length === 0) return

      const markAsSeenRes = await api.jobs.jobApplicant.markInvitedAsSeen({
        data: { jobInvitedUserIds: invites },
      })

      if (markAsSeenRes.isSuccessful) updateNotifications()
      else console.error(markAsSeenRes.errors)
    }, 5000)

    return () => {
      window.clearTimeout(timer)
    }
  }, [applications, updateNotifications])
  const listApplicants = async () => {
    const res = await api.jobs.jobApplicant.listPendingApplications({
      params: {
        page,
        application_type: applicationType,
        ordering: `${dateOrderingRecent ? "" : "-"}date`,
      },
    })
    if (res.isSuccessful) {
      setApplications(res.data)
      setLoading(false)
    }
  }
  useEffect(() => {
    setLoading(true)

    listApplicants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, dateOrderingRecent, applicationType])

  const withdrawApplication = async (reason: Reason[]) => {
    if (!dialog) return
    const { actionItem } = dialog

    if (!actionItem) return
    let handler = null

    if (actionItem.jobApplicant) {
      handler = api.jobs.jobApplicant.withdraw
    } else if (
      actionItem.source === ApplicationSource.JOB_INTERESTED_USER &&
      !actionItem.jobApplicant
    ) {
      handler = api.jobs.jobApplicant.updateJobInterestedUser
    }

    if (!handler) {
      return
    }
    const id = actionItem.jobApplicant
      ? actionItem.jobApplicant.id
      : actionItem.id

    const res = await handler({
      urlParams: {
        jobApplicantId: id,
      },
      data: {
        status: JobApplicantStatus.WITHDRAWN,
        rejectionReason: reason,
      },
    })

    if (res.isSuccessful) {
      setApplications(item => ({
        ...item,
        data: item.data.filter(v => v.id !== actionItem?.id),
      }))
      setOverview(prevState => ({
        ...prevState,
        totalApplied: prevState.totalApplied - 1,
      }))
      toast.success("Application withdrawn")
      setDialog(null)
    } else {
      toast.error(res.errors.message)
    }
  }

  if (loading) {
    return (
      <div className="mt-5 flex justify-center">
        <CircularProgress />
      </div>
    )
  }

  if (applications.data.length === 0)
    return (
      <NoDataCard
        className="mt-3"
        message="You don't have any pending applications yet."
      />
    )

  const actionItem = dialog?.actionItem

  return (
    <>
      <div className={clsx(classes.root, "my-3")}>
        <div className={classes.container}>
          <div className={clsx(classes.tableGrid, "py-1")}>
            <div />
            <Typography variant="strongSmallBody">Name</Typography>

            <div>
              <div className="flex items-center gap-0.5">
                <Typography variant="strongSmallBody">
                  Date Invited/Applied
                </Typography>
                <IconButton onClick={() => setDateOrderingRecent(v => !v)}>
                  {dateOrderingRecent ? (
                    <ArrowDown className="size-2 text-onSurface-500" />
                  ) : (
                    <ArrowUp className="size-2 text-onSurface-500" />
                  )}
                </IconButton>
              </div>
            </div>

            <div />

            <div className="flex w-full justify-end">
              <Menu
                menuButton={
                  <Button color="white" size="sm">
                    {capitalize(applicationType)} <NavArrowDown />
                  </Button>
                }
              >
                {Object.values(ApplicationType).map((v, i) => (
                  <MenuItem key={i} onClick={() => setApplicationType(v)}>
                    {capitalize(v)}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          {applications.data.map((item, index) => {
            const expiry = () => {
              if (!item.jobApplicant || !item.invitationExpiresAt) {
                return null
              }
              const inviteExpiry = new Date(item.invitationExpiresAt)
              const timeLeft = differenceInCalendarDays(
                inviteExpiry,
                new Date()
              )
              const showExpiry = timeLeft <= 5
              if (showExpiry && inviteExpiry) {
                return (
                  <Typography color="critical.600" variant="smallBody">
                    Invite expires{" "}
                    {!isToday(inviteExpiry) ? (
                      <span>
                        today
                        <br />
                      </span>
                    ) : (
                      <span>
                        in {timeLeft} {timeLeft === 1 ? "day" : "days"}
                        <br />
                        {format(inviteExpiry, "d MMM yyyy")},{" "}
                      </span>
                    )}
                    {format(inviteExpiry, "h:mm a")}
                  </Typography>
                )
              }
              return null
            }

            return (
              <div
                id={item.id.toString()}
                className={clsx(classes.tableGrid, classes.tableItem, "py-2")}
                key={index}
              >
                <div className="mt-0.5 pl-3">
                  <SchoolLogo
                    className={classes.image}
                    src={item.school.logo}
                  />
                </div>

                <div>
                  <div className="flex items-center gap-0.5">
                    <Button
                      className="-ml-0.75"
                      component={Link}
                      to={`${routes.schoolProfile.replace(
                        ":slug",
                        item.school.slug
                      )}?jobSlug=${item.job.id}`}
                      variant="link"
                    >
                      <Typography
                        color="interactive.600"
                        display="inline"
                        variant="smallBody"
                      >
                        {item.school.name}
                      </Typography>
                    </Button>
                  </div>
                  {item.school.state?.name && item.school.country?.name && (
                    <Typography
                      className={clsx(classes.textSmall, "mb-0.5")}
                      color="onSurface.500"
                    >
                      {item.school.state.name}, {item.school.country.name}
                    </Typography>
                  )}

                  <Typography variant="smallBody">
                    {item.job.position}
                  </Typography>

                  <Typography variant="smallBody">
                    {item.job.subject.name}
                  </Typography>
                </div>

                <div className="flex flex-col justify-center">
                  <Typography variant="smallBody">
                    {format(new Date(item.date), "d MMM yyyy")}
                  </Typography>
                  {expiry()}
                </div>
                <div className="flex flex-col justify-center">
                  {item.source === ApplicationSource.JOB_INTERESTED_USER &&
                    item.jobInvitedUser && (
                      <Tag color="one" label="Under Review" />
                    )}
                </div>
                <div className="mr-2 flex flex-col items-end justify-center">
                  {item.source === ApplicationSource.JOB_INVITED_USER && (
                    <div className="flex items-center">
                      <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                          setDialog({
                            mode: "accept",
                            actionItem: item,
                          })
                        }}
                      >
                        Accept Invite
                      </Button>
                      <Button
                        color="critical"
                        variant="text"
                        onClick={() => {
                          setDialog({
                            mode: "reject",
                            actionItem: item,
                          })
                        }}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                  {item.source === ApplicationSource.JOB_INTERESTED_USER && (
                    <Button
                      color="critical"
                      variant="text"
                      onClick={() => {
                        setDialog({
                          mode: "withdraw",
                          actionItem: item,
                        })
                      }}
                    >
                      Withdraw Application
                    </Button>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {applications.total > 10 && (
        <Pagination page={page} total={applications.total} onChange={setPage} />
      )}

      {actionItem && (
        <RejectApplicationDialog
          afterSubmit={() => {
            setApplications(prevState => ({
              ...prevState,
              data: prevState.data.filter(({ id }) => id !== actionItem.id),
            }))
            setOverview(prevState => ({
              ...prevState,
              totalInvites: prevState.totalInvites - 1,
            }))
            // updateNotifications()
          }}
          handleClose={() => {
            setDialog(null)
          }}
          jobApplicantId={actionItem.id}
          jobPosition={actionItem.job.position}
          mode={dialog.mode === "withdraw" ? "withdraw" : "invitation"}
          open={["withdraw", "reject"].includes(dialog.mode)}
          schoolName={actionItem.school.name}
          subjectName={actionItem?.job.subject.name}
          onWithdrawHandler={
            dialog.mode === "withdraw" ? withdrawApplication : async () => {}
          }
        />
      )}

      {actionItem && (
        <AcceptApplicationDialog
          afterSubmit={() => {
            setDialog(null)
            updateNotifications()
            if (actionItem.actionBy === ActionBy.SCHOOL) {
              setApplications(item => ({
                ...item,
                data: item.data.filter(v => v.id !== actionItem?.id),
              }))
              setOverview(prevState => ({
                ...prevState,
                totalInvites: prevState.totalInvites - 1,
                totalActiveApplications: prevState.totalActiveApplications + 1,
              }))
            } else {
              listApplicants()
            }

            toast.success("Invite accepted")
          }}
          handleClose={() => {
            setDialog(null)
          }}
          jobApplicantId={actionItem.id}
          jobPosition={actionItem.job.position}
          open={dialog.mode === "accept"}
          schoolName={actionItem.job.subject.name}
          subjectName={actionItem.job.subject.name}
        />
      )}
    </>
  )
}
export default PendingApplicationsTab
